<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-7">

          <ValidationProvider name="street" rules="required|min:5" v-slot="{ passed, failed, errors }">
            <base-input v-model="street" label="Street Name" :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>

          <ValidationProvider name="city" rules="required" v-slot="{ passed, failed, errors }">
            <base-input v-model="city" label="City" :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
          <label>Country</label>

          <ValidationProvider name="country" rules="required|min:5">
            <base-input>
              <el-select v-model="country" class="select-primary" name="country">
                <el-option v-for="country in countryOptions" class="select-primary" :label="country" :value="country"
                  :key="country"></el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>
        <!-- <div class="col-sm-10">
          <base-button type="info" @click="addLocationDetails">Save</base-button>
        </div> -->
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { Select, Option } from 'element-ui';
import { api_url } from '../../../env';

extend("required", required);
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      street: '',
      city: '',
      country: '',
      countryOptions: [
        'Kenya',
        'Germany',
        'Netherlands',
        'USA',
        'UK',
        'New Zealand'
      ]
    };
  },
  methods: {
    getUser() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/user', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (!response.ok) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.user = response.user[0]
              this.agencyName = response.user["agency_name"]
              this.firstName = response.user["first_name"]
              this.lastName = response.user["last_name"]
              this.email = response.user["email"]
              let accountDetails = JSON.parse(response.user.agency_details)

              this.phoneNumber = accountDetails.mobile
              this.src = accountDetails.profile_path
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
        })
    },

    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        this.addLocationDetails();
        return res;
      });
    },
    addLocationDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Content-Type": "application/json",

      };

      var postData = {
        street_name: this.street,
        city: this.city,
        country: this.country
      }

      this.$http.post(api_url + '/agent/addLocationDetails', postData, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          console.log(response)
          if (!response.ok) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              console.log(response["message"]);
              this.alertNotification("top", "right", "success", response.message)
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }


        })
    },
    getLocationDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/agent', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (!response.ok) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {

              // let locationDetails =JSON.parse(response.user.agency.locationDetails)
              // this.street = locationDetails.street

            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
        })
    },
    alertNotification(verticalAlign, horizontalAlign, color, message) {
      this.$notify({
        message: message,
        timeout: 30000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color
      });
    }

  },

  created() {
    this.getUser();
  }
}


  ;
</script>
<style></style>
  