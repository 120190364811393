    <template>
    <div>

        <div class="row">
            <div class="col-md-6">
                <card>
                    <img slot="image" class="card-img-top" :src="agent.agency_details.profile_path" alt="Card image cap" />

                </card>
            </div>

            <div class="col-md-6">
                <card>
                    <template slot="header">
                        <h3 class="card-title">{{ agent.agency_name }} </h3>
                    </template>
                    <tabs>
                        <tab-pane label="Profile">
                            <li class="profile">Contact Person<b> : {{ " " + agent.first_name + " " + agent.last_name }}</b>
                            </li>
                            <li class="profile">Phone Number<b>:{{ agent.agency_details.mobile }}</b></li>
                            <li class="profile">Email<b>:{{ agent.email }}</b></li>
                            <li class="profile">Location <b>: {{ " " + agent.location_details.street_name }} ({{
                                agent.location_details.city + "," + agent.location_details.country }})</b></li>


                        </tab-pane>
                        <tab-pane label="Models">
                            <div class="d-flex" style="flex-wrap: wrap;">

                                <div class="datum-grid col-lg-3 col-md-2 col-3 mb-1" v-for="model in models">
                                    <a @click="$router.push('/model/' + model.id )">
                                    <div class="card">
                                        <img slot="image" class="card-img-top" :src="model.personal_details.profile_path"
                                            alt="Card image cap" />
                                    </div>
                                    <div class="card-img-overlay text-white d-flex flex-column justify-content-center">
                                        <span class="overlay_text"> Name: {{ model.personal_details.nick_name }}</span>
                                        <span class="overlay_text"> Location: {{ model.location_details.city }}</span>
                                        <span class="overlay_text">Age : {{ " " +
                                            calculateAge(model.personal_details.date_of_birth)
                                        }} years</span>
                                    </div>
                                    </a>
                                </div>
                            </div>

                        </tab-pane>


                    </tabs>
                </card>
            </div>
        </div>

    </div>
</template>
<script>
import { TabPane, Tabs } from 'src/components';
import { api_url, datumMixin } from '../../env';

export default {
    name: "AgentProfile",
    mixins: [datumMixin],
    components: {
        TabPane,
        Tabs
    },
    data() {
        return {
            user_id: 0,
            agent_id: 0,
            agent: [],
            models: [],
        };
    },
    methods: {
        getAgent() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/agents/' + this.agent_id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            let agent = response.agent[0]
                            agent.agency_details = JSON.parse(agent.agency_details)
                            agent.location_details = JSON.parse(agent.location_details)
                            agent.payment_details = JSON.parse(agent.payment_details)
                            agent.services = JSON.parse(agent.services)
                            this.agent = agent

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })
        },
        getAgentModels() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/models?agent_id=' + this.user_id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            let models = response.models
                            models.forEach(model => {
                                model.personal_details = JSON.parse(model.personal_details)
                                model.location_details = JSON.parse(model.location_details)
                            });
                            this.models = models

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })
        },

    },
    created() {
        this.user_id = this.$route.params.user_id;
        this.agent_id = this.$route.params.agent_id;
        this.getAgent();
        this.getAgentModels();
    }
};
</script>
<style lang="scss">
.profile {
    display: table-row;
}

.profile b {
    display: table-cell;
    padding-right: 1em;

}
</style>
  