<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div>
                <h5 class="info-text">
                    How you will get paid
                </h5>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-5">

                        <ValidationProvider name="Mpesa Number" rules="required|min:5" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="mobileNumber" placeholder="Mpesa Number"
                                addon-left-icon="tim-icons icon-money-coins" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>
                   
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";

import {
    ImageUpload,
    
} from 'src/components/index';

extend("email", email);
extend("required", required);
extend("numeric", numeric);


export default {
    name : "PaymentDetails",
    
    data() {
        return {
            mobileNumber: '',
            
        };
    },
    methods: {
        validate() {
      return this.$refs.form.validate().then(res => {
        if (!res) {
          return;
        }
        this.$emit("on-validated", res);
        return res;
      });
    }
    }
};
</script>
<style></style>
  