<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div>
                <h5 class="info-text">
                    Let's start with the basic information (with validation)
                </h5>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-5">
                        <label>First Name</label>
                        <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="firstName" placeholder="First Name"
                                addon-left-icon="tim-icons icon-single-02" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Last Name</label>
                        <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="lastName" placeholder="Last Name"
                                addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Email</label>
                        <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                            <base-input disabled required v-model="email" placeholder="Email"
                                addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>D.O.B</label>
                        <ValidationProvider name="DOB" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input :class="[{ 'has-success': passed }, { 'has-danger': failed }]" :error="errors[0]">
                                <el-date-picker type="date" placeholder="Date of birth" v-model="date_of_birth">
                                </el-date-picker>
                            </base-input>
                        </ValidationProvider>
                        <label>Bust Size</label>
                        <ValidationProvider name="bust_size" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="bust_size" placeholder="In cm(Shown on profile)"
                                addon-left-icon="tim-icons icon-camera-18" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Availability</label>
                        <ValidationProvider name="availability" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="availability" placeholder="1am - 3am(Shown on profile)"
                                addon-left-icon="tim-icons icon-watch-time" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Languages</label>
                        <ValidationProvider name="languages" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="languages"
                                placeholder="Languages - comma sepatated(Shown on profile)"
                                addon-left-icon="tim-icons icon-badge" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-5">
                        <label>Middle Name</label>
                        <ValidationProvider name="middleName" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="middleName" placeholder="Middle Name"
                                addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Pet Name</label>
                        <ValidationProvider name="petName" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="petName" placeholder="Pet Name (Shown on Home)"
                                addon-left-icon="tim-icons icon-heart-2" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Phone</label>
                        <ValidationProvider name="phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="phone" placeholder="Phone(Not shown to public)"
                                addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Hair Color</label>
                        <ValidationProvider name="hair_color" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="hair_color" placeholder="Hair Color e.g Blond, brunnette)"
                                addon-left-icon="tim-icons icon-camera-18" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Height</label>
                        <ValidationProvider name="height" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="height" placeholder="Height(Shown on profile)"
                                addon-left-icon="tim-icons icon-camera-18" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <label>Hip Size</label>
                        <ValidationProvider name="hip_size" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="hip_size" placeholder="Hip Size(in cm)"
                                addon-left-icon="tim-icons icon-camera-18" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>

                    </div>
                    <div class="col-sm-10">
                        <label>Display Picture (Shown on Escorts Page)</label>
                        <image-upload @change="onImageChange" select-text="Select Image" :src="src" style="width: 100% ;" />


                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { api_url,datumMixin } from '../../../env';


import {
    ImageUpload,

} from 'src/components/index';
import { color } from "d3";



extend("email", email);
extend("required", required);
extend("numeric", numeric);


export default {
    name: "AboutMe",
    components: {
        ImageUpload,
        [DatePicker.name]: DatePicker,
    },
    mixins: [datumMixin],
    data() {
        return {
            firstName: '',
            lastName: '',
            middleName: '',
            petName: '',
            email: '',
            phone: '',
            date_of_birth: '',
            profile_image: '',
            src: '',
            languages: '',
            hip_size: '',
            height: '',
            availability: '',
            bust_size: '',
            hair_color: ''

        };
    },
    methods: {
        validate() {
            return this.$refs.form.validate().then(res => {
                this.$emit("on-validated", res);
                this.addPersonalDetails()
                return res;
            });
        },

        getUser() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/user', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            response.user = response.user[0]
                            this.firstName = response.user["first_name"]
                            this.middleName = response.user["middle_name"]
                            this.lastName = response.user["last_name"]
                            this.email = response.user["email"]
                            let personalDetails = JSON.parse(response.user.personal_details)
                            this.date_of_birth = personalDetails.date_of_birth
                            this.phone = personalDetails.mobile
                            this.petName = personalDetails.nick_name
                            this.src = personalDetails.profile_path
                            this.languages = personalDetails.languages
                            this.hip_size = personalDetails.hip_size
                            this.height = personalDetails.height
                            this.availability = personalDetails.availability
                            this.bust_size = personalDetails.bust_size
                            this.hair_color = personalDetails.hair_color


                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },

      
        addPersonalDetails() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",

            };

            var formdata = new FormData();
            formdata.append("first_name", this.firstName);
            formdata.append("last_name", this.lastName);
            formdata.append("middle_name", this.middleName);
            formdata.append("nick_name", this.petName);
            formdata.append("date_of_birth", this.date_of_birth);
            formdata.append("email", this.email);
            formdata.append("mobile", this.phone);
            formdata.append("profile_image", this.profile_image);
            formdata.append("languages", this.languages);
            formdata.append("hip_size", this.hip_size);
            formdata.append("height", this.height);
            formdata.append("availability", this.availability);
            formdata.append("bust_size", this.bust_size);
            formdata.append("hair_color", this.hair_color);

            this.$http.post(api_url + '/models/addPersonalDetails', formdata, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    console.log(response)
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            console.log(response["message"]);
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }


                })
        },
        onImageChange(file) {
            this.profile_image = file;
        },
       

    },
    created() {
        this.getUser();
    }
};
</script>
<style></style>
  