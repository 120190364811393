<template>
    <div>



        <div>
            <h6>{{ title }}</h6>
            <div class="d-flex" style="flex-wrap: wrap;">


                <div class="d-flex" style="overflow-x: auto; overflow-y: hidden ;">

                    <div class="datum-grid col-lg-2 col-md-2 col-3 mb-1" v-for="agent in agencies">
                        <a @click="$router.push('/agent/' + agent.user_id + '/' + agent.id)">
                            <div class="card home-cards" @contextmenu="showPopUpImage(agent, 'agency')">
                                <img slot="image" class="card-img-top" :src="agent.agency_details.profile_path"
                                    alt="Card image cap" />
                                <div class="card-img-overlay text-white d-flex flex-column justify-content-center">
                                    <span class="overlay_text"><span style="color: red; ">●</span> {{ agent.agency_name
                                    }}</span>
                                    <span class="overlay_text"> Model Count: {{ agent.count }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div id="imageModal" class="modal custom-modal" style="display: none;" oncontextmenu="return false;">
            <span class="close">&times;</span>
            <img id="modalImage" src="" alt="Modal Image">
            <div id="caption"></div>
        </div>
    </div>
</template>
<script>
//import VideoPlayer from 'src/components/Video.vue';
import { api_url, datumMixin } from '../../env';
export default {
    name: 'home-page',
    layout: 'default',
    mixins: [datumMixin],


    data() {
        return {
            agencies: [],
            title: "",
            criteria: ""
        };
    },
    methods: {

        getAgencies() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/agents'+this.criteria, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {

                            //steps
                            //1. Get all the data and prep it
                            response.agents.forEach(agent => {
                                let location_details = (agent.location_details) ? JSON.parse(agent.location_details) : null
                                let agency_details = (agent.agency_details) ? JSON.parse(agent.agency_details) : null
                                let payment_details = (agent.payment_details) ? JSON.parse(agent.payment_details) : null

                                //2 . Prep it
                                agent.location_details = location_details
                                agent.agency_details = agency_details
                                agent.payment_details = payment_details

                              

                            });


                            //3. Assign it to this.model
                            this.agencies = response.agents


                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                },
                )
        },

        showPopUpImage(entity, type) {
            const mediaQuery = window.matchMedia('(max-width: 990px)');
            if (!mediaQuery.matches) {
                return false;

            }

            let image = ""
            let entityName = ""
            let age = 0
            let model_count = 0
            let captionText = ""
            let location = ""
            if (type == "agency") {
                image = entity.agency_details.profile_path
                entityName = entity.agency_name
                model_count = entity.count

                captionText = "Model Count : " + model_count


            }
            else if (type == "model") {
                image = entity.personal_details.profile_path
                entityName = entity.personal_details.nick_name
                age = this.calculateAge(entity.personal_details.date_of_birth)
                location = entity.location_details.city.charAt(0).toUpperCase() +
                    entity.location_details.city.slice(1)
                captionText = "Name : " + entity.personal_details.nick_name + "<br> Age : " + age + " years" + " <br> Location :  " + location
            }
            console.log(captionText)

            if (type == "model") {
                captionText += "<br> <a class='btn btn-primary' href='/#/model/" + entity.id + "'>View Profile</a>"
            }
            else if (type == "agency") {
                captionText += "<br> <a class='btn btn-primary' href='/#/agent/" + entity.user_id + "/" + entity.id + "'>View Profile</a>"
            }
            console.log(captionText)



            const modal = document.getElementById("imageModal");
            const modalImage = document.getElementById("modalImage");
            const caption = document.getElementById("caption");

            modal.style.display = "block";
            modalImage.src = image;
            caption.innerHTML = captionText;

            document.querySelector(".close").addEventListener("click", () => {
                modal.style.display = "none";
            });



        },




    },
    created() {
        this.title = this.$route.params.title;
        this.criteria = this.$route.params.criteria;
        this.getAgencies()
        //this.calculateAge(birthDate);

    }

};
</script>
