<!-- source https://www.bootdey.com/snippets/view/chat-app#preview -->
<template>
    <div>
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

        <div class="container">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card chat-app">
                        <div id="plist" class="people-list">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                                </div>
                                <input type="text" class="form-control" placeholder="Search...">
                            </div>
                            <ul class="list-unstyled chat-list mt-2 mb-0">
                                <!-- new chat -->
                                <li class="clearfix" @click="getModelsAndList()">

                                    <div class="about">
                                        <button class="btn btn-primary">New Chat</button>
                                    </div>
                                </li>
                                <li class="clearfix" @click="openChat(thread.title, thread.participantId, thread.picture)"
                                    v-for="thread in threads ">
                                    <img v-if="thread.picture == ''" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="avatar">
                                    <img v-else :src="thread.picture" alt="avatar">
                                    <div class="about">
                                        <div class="name">{{ thread.title }}</div>
                                        <small class="text-muted">{{ thread.last_message }}</small>
                                        <!-- <div class="status"> <i class="fa fa-circle offline"></i> left 7 mins ago </div> -->
                                    </div>
                                </li>



                            </ul>

                        </div>
                        <div id="new_plist" class="people-list" style="display: none;">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                                </div>
                                <input type="text" class="form-control" placeholder="Search For New Models ">
                            </div>
                            <ul class="list-unstyled chat-list mt-2 mb-0">
                                <!-- new chat -->
                                <li class="clearfix" @click="goToThreads()">

                                    <div class="about">
                                        <i style="font-size: large;" class="fa fa-arrow-left m-2"></i> Back
                                    </div>
                                </li>
                                <li class="clearfix" @click="openChat(model.personal_details.nick_name, model.user_id, model.personal_details.profile_path , model.first_name)"
                                    v-for="model in models ">
                                    <img :src="model.personal_details.profile_path" alt="profile">

                                    <div class="about">
                                        <div v-if="model.personal_details.nick_name" class="name">{{ model.personal_details.nick_name }}</div>
                                        <div v-else class="name">{{ model.first_name }}</div>
                                        <div class="status"> <i class="fa fa-circle online"></i> Online </div>
                                        <!-- <div class="status"> <i class="fa fa-circle offline"></i> left 7 mins ago </div> -->
                                    </div>
                                </li>



                            </ul>

                        </div>
                        <div class="chat">
                            <div class="chat-header clearfix">
                                <div class="row">
                                    <div class="col-lg-6">


                                        <div class="chat-about">
                                            <div class="d-flex">
                                                <i @click="closeChat()" style="font-size: large;"
                                                    class="fa fa-arrow-left m-2"></i>
                                                <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">

                                                    <img :src="chat.picture"
                                                        alt="avatar">
                                                </a>
                                                <h6 style="color: #efefef;" class="m-b-0 m-2">{{ chat.title }}</h6>
                                            </div>

                                            <!-- <small>Last seen: 2 hours ago</small> -->
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-6 hidden-sm text-right">
                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="fa fa-camera"></i></a>
                            <a href="javascript:void(0);" class="btn btn-outline-primary"><i class="fa fa-image"></i></a>
                            <a href="javascript:void(0);" class="btn btn-outline-info"><i class="fa fa-cogs"></i></a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning"><i class="fa fa-question"></i></a>
                        </div> -->
                                </div>
                            </div>
                            <div class="chat-history" id="chat-hist">
                                <ul class="m-b-0">


                                    <li class="clearfix" v-for="message in chat.messages">

                                        <div class="message-data text-right" v-if="message.type == 'my-message'">
                                            <span class="message-data-time">{{ getDisplayDate(message.updated_at) }}</span>
                                        </div>
                                        <div class="message-data" v-else>
                                            <span class="message-data-time">{{ getDisplayDate(message.updated_at) }}</span>
                                        </div>


                                        <div class="message my-message float-right" v-if="message.type == 'my-message'">{{
                                            message.body
                                        }}</div>
                                        <div class="message other-message" v-else> {{ message.body }} </div>
                                    </li>



                                </ul>
                            </div>
                            <div class="chat-message clearfix">
                                <div class="input-group mb-0">

                                    <input type="text" id="messageBox" class="form-control"
                                        placeholder="Enter text here...">
                                    <div class="input-group-prepend" @click="newMessage()">
                                        <span class="input-group-text" id="sendButton"><i class="fa fa-send"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</template>
<script>
import { api_url, datumMixin } from '../../env';
import { Modal } from 'src/components';


export default {

    name: 'starter-page',
    layout: 'default',
    mixins: [datumMixin],
    components: { Modal },
    data() {
        return {
            threads: [],
            participantId: 0,
            chat: {
                title: '',
                messages: [],
            },
            models: [],
            loadThreadsInterval: null,
            loadChatsInterval: null,

        };
    },
    methods: {

        goToThreads() {
            //show modal with a list of users that are  filterable
            //this.newChatModal = true;
            //on selecting user, open chat
            //hide people list in mobile
            document.getElementById('new_plist').style.display = 'none';
            document.getElementById('plist').style.display = 'block';
            this.loadChats();



        },
        async openChat(title, participantId, picture = null , alternative_title = null) {
            console.log('open chat');
            clearInterval(this.loadChatsInterval);

            //hide people list
            //show chat
            //clear chat messages
            // this.chat.messages = []

            // only when on mobile
            const mediaQuery = window.matchMedia('(max-width: 768px)');
            if (mediaQuery.matches) {
                document.getElementById('plist').style.display = 'none';
                document.getElementsByClassName('chat')[0].style.display = 'block';
                document.getElementsByClassName('chat')[0].style.marginLeft = 0;

            }

            // set key bind to send button
            document.getElementById('messageBox').addEventListener("keyup", function (event) {
                console.log(event.keyCode);
                console.log(event);
                if (event.keyCode === 13) {
                    event.preventDefault();
                    document.getElementById('sendButton').click();
                }
            });

            //{"status":"true","threads":[{"id":2,"participants":"2,13","created_at":"2023-09-15T14:11:41.000000Z","updated_at":"2023-09-15T14:11:41.000000Z","title":"Tiffany Jonni","last_message":"O O"}]}

            // get second particiapant

            
            this.chat.title = (title)? title : alternative_title
            this.participantId = participantId
            if(picture != null){
                this.chat.picture = picture
            }
            else{
                this.chat.picture = "https://bootdey.com/img/Content/avatar/avatar2.png"
            }

            let isMessagesLoaded = await this.getChatMessages()
            console.log(isMessagesLoaded);
            if (isMessagesLoaded) {
                //scroll to  the  end
                let objDiv = document.getElementById("chat-hist")
                objDiv.scrollTop = objDiv.scrollHeight;
                //get last message id


                //periodic  poll for  new message
                let vm = this
                if (await this.getChatMessages()) {
                    this.loadChatsInterval = setInterval(async function ()  { //long polling
                        console.log("Interval triggered"); // Add this line for debugging
                        let last_message = vm.chat.messages[vm.chat.messages.length - 1]
                        console.log(vm.chat.messages.length);
                        let isMessagesLoaded = await vm.getChatMessages("true", last_message.id)
                        if (isMessagesLoaded) {
                            //scroll to  the  end
                            let objDiv = document.getElementById("chat-hist")
                            objDiv.scrollTop = objDiv.scrollHeight;
                        }
                    },3000)
                }


            }

           


        },
        closeChat() {

            // only when on mobile
            const mediaQuery = window.matchMedia('(max-width: 768px)');
            if (mediaQuery.matches) {
                document.getElementById('plist').style.display = 'block';
                document.getElementsByClassName('chat')[0].style.display = 'none';
                // document.getElementsByClassName('chat')[0].style.marginLeft = 0;

            }
        },
        loadChats() {
            //load chats from db
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };
            this.$http.get(api_url + '/chat/threads', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {

                            this.threads = response.threads
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                },
                )
        },
        newMessage() {
            //send message to db
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };
            let message = document.getElementById('messageBox').value
            let data = {
                participantId: this.participantId,
                body: message
            }
            if (message == '') {
                this.alertNotification("top", "right", "warning", "Message cannot be empty")
                return
            }


            this.$http.post(api_url + '/chat/create', data, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {

                            // add the message to messages object
                            //add type to message
                            response.message.type = 'my-message'
                            this.chat.messages.push(response.message)
                            // clear the input field
                            document.getElementById('messageBox').value = ''
                             //scroll to  the  end
                             let objDiv = document.getElementById("chat-hist")
                            objDiv.scrollTop = objDiv.scrollHeight;
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                },

                    this.goToThreads()
                )

        },
        getModelsAndList(skip = 0, take = 20) {
            //clear models
            if (skip == 0 && take == 20) {
                this.models = []
            }

            //send message to db
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };


            this.$http.get(api_url + '/models?skip=' + skip + '&take=' + take, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            //steps
                            //1. Get all the data and prep it
                            response.models.forEach(model => {
                                let location_details = (model.location_details) ? JSON.parse(model.location_details) : null
                                let personal_details = (model.personal_details) ? JSON.parse(model.personal_details) : null
                                let payment_details = (model.payment_details) ? JSON.parse(model.payment_details) : null

                                //2 . Prep it
                                model.location_details = location_details
                                model.personal_details = personal_details
                                model.payment_details = payment_details

                                this.models.push(model)

                            });



                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                },
                )
            document.getElementById('plist').style.display = 'none';
            document.getElementById('new_plist').style.display = 'block';
        },
        async getChatMessages(poll = "false", last_message_id = 0) {
            // get chat messages
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };
            return this.$http.get(api_url + '/chat/messages?participantId=' + this.participantId + '&poll=' + poll + '&last_message_id=' + last_message_id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status > 299) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            return false
                            // this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {

                            if (poll == "true") {
                                if (response.messages.length > 0) {
                                    response.messages.forEach(message => {
                                        if(message.user_id != localStorage.getItem('user_id')){
                                            message.type = 'other-message'
                                            this.chat.messages.push(message)
                                        }
                                    });
                                    //this.chat.messages.push(response.messages)
                                }
                            } else {
                                this.chat.messages = response.messages
                            }

                            let user_id = localStorage.getItem('user_id')
                            this.chat.messages.forEach(message => {
                                if (message.user_id == user_id) {
                                    message.type = 'my-message'
                                }
                                else {
                                    message.type = 'other-message'
                                }
                            })


                            return true;

                        }
                        else {
                            // this.alertNotification("top", "right", "danger", response.statusText)
                            return false
                        }
                    }
                },
                )

        }

    },
    created() {
        this.loadChats();
        //periodic  poll for  new message
        this.loadThreadsInterval =  setInterval(async () => {

         this.loadChats()
    
        }, 10000);
    },
    destroyed() {
         clearInterval(this.loadChatsInterval);
         clearInterval(this.loadThreadsInterval);
    },

};
</script>
<style>
.chat-app {
    background: #1e1e2c;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: .55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.chat-app .people-list {
    width: 280px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    z-index: 7
}

.chat-app .chat {
    margin-left: 280px;
    border-left: 1px solid #2f3f65
}

.people-list {
    -moz-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s
}

.people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 3px
}

.people-list .chat-list li:hover {
    background: #2f3f65;
    cursor: pointer
}

.people-list .chat-list li.active {
    background: #2f3f65
}

.people-list .chat-list li .name {
    font-size: 15px
}

.people-list .chat-list img {
    width: 45px;
    border-radius: 50%
}

.people-list img {
    float: left;
    border-radius: 50%
}

.people-list .about {
    float: left;
    padding-left: 8px
}

.people-list .status {
    color: #999;
    font-size: 13px
}

.chat .chat-header {
    padding: 15px 20px;
    border-bottom: 2px solid #2f3f65;
}

.chat .chat-header img {
    float: left;
    border-radius: 40px;
    width: 40px
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px
}

.chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #2f3f65;
}

.chat .chat-history ul {
    padding: 0
}

.chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
    margin-bottom: 0px
}

.chat .chat-history .message-data {
    margin-bottom: 15px
}

.chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px
}

.chat .chat-history .message-data-time {
    color: #434651;
    padding-left: 6px
}

.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .my-message {
    background: #efefef
}

.chat .chat-history .my-message:after {
    bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #efefef;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .other-message {
    background: #e8f1f3;
    text-align: right
}

.chat .chat-history .other-message:after {
    border-bottom-color: #e8f1f3;
    left: 93%
}

.chat .chat-message {
    padding: 20px
}

.online,
.offline,
.me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle
}

.online {
    color: #86c541
}

.offline {
    color: #e47297
}

.me {
    color: #1d8ecd
}

.float-right {
    float: right
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

@media only screen and (max-width: 767px) {
    .chat-app .people-list {
        height: calc(100vh - 50px);
        width: 100%;
        overflow-x: auto;
        display: block
    }

    .chat-app .people-list.open {
        left: 0
    }

    .chat-app .chat {
        display: none;
    }

    .chat-app .chat .chat-header {
        border-radius: 0.55rem 0.55rem 0 0
    }

    .chat-app .chat-history {
        height: calc(100vh - 300px);
        overflow-x: auto;
    }

    .chat-app .chat {
        border-left: none;
        margin-left: 0;
    }

    .chat-app .chat {
        margin-left: 280px;
        border-left: 1px solid #2f3f65
    }

    /* show people list by default  until a  chat is  clicked  where  we open a chat history */


}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app .chat-list {
        height: 650px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: 600px;
        overflow-x: auto
    }
}

@media only screen and (min-device-width: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app .chat-list {
        height: 480px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: calc(100vh - 350px);
        overflow-x: auto
    }
}
</style>
  