<template>
    <div class="col-12">
        <h2 class="card-title">Categories</h2>
        <base-button type="primary" @click.native="modals.createCategory = true">
            Create Category
        </base-button>
        <card>
            <div class="row">

                <div class="col-md-12 col-12 mb-auto">



                    <el-table header-cell-class-name="table" header-row-class-name="table-transparent"
                        row-class-name="table-transparent" :data="categories">
                        <el-table-column min-width="150" sortable label="Name" property="name"></el-table-column>
                        <el-table-column min-width="150" sortable label="Description"
                            property="description"></el-table-column>
                        <el-table-column min-width="150" sortable label="Action">
                            <div slot-scope="{ row }" class="">
                                <a @click="editCategory(row.id)" class="btn btn-primary">Edit</a>
                                <a @click="deleteCategory(row.id)" class="btn btn-danger">Delete</a>
                            </div>
                        </el-table-column>

                    </el-table>




                </div>
            </div>

            <!-- Classic Modal -->
            <modal :show.sync="modals.createCategory" headerClasses="justify-content-center">
                <h4 slot="header" v-if="editing == false" class="title title-up">New Category</h4>
                <h4 slot="header" v-if="editing" class="title title-up">Update Category</h4>
                <div class="row">
                    <input type="hidden" class="form-control" v-model="postData.id">
                    <div class="col-md-6 col-lg-6 col-12">
                        <h4 style="color: black ;">Name</h4>
                        <!-- <base-input style="color: black;" v-model="postData.name" placeholder="Enter Caption"> </base-input> -->
                        <input type="text" class="form-control" v-model="postData.name" placeholder="Enter Name">
                    </div>
                    <div class="col-md-6 col-lg-6 col-12">
                        <h4 style="color: black ;">Description</h4>
                        <input type="text" class="form-control" v-model="postData.description"
                            placeholder="Enter Description">
                    </div>
                </div>

                <template slot="footer">
                    <div class="row">
                        <base-button v-if="editing == false" @click="addCategory()">Save</base-button>
                        <base-button v-else @click="updateCategory()">Update</base-button>

                    </div>

                </template>
            </modal>
        </card>
    </div>
</template>

<script>


import { Select, Option, Table, TableColumn } from 'element-ui';
import { TabPane, Tabs, Modal, BaseAlert } from 'src/components';
import { api_url } from '../../env';

export default {
    name: "Categories",
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        Modal
    },
    data() {
        return {
            tableData: [
                {
                    id: 1,
                    name: 'Black',
                    description: 'Black people',
                    action: '<a class="btn btn-primary">Edit</a>',
                    city: 'Oud-Turnhout'
                },

            ],
            categories: [],
            postData: {
                id: "",
                name: "",
                description: "",
            },
            modals: {
                createCategory: false,

            },
            editing: false
        };
    },
    methods: {

        addCategory() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.post(api_url + '/categories', this.postData, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                        this.getCategories()
                        this.modals.createCategory = false

                    }


                })
        },
        getCategories() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/categories', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.categories = response.categories
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        editCategory(id) {
            //get the name and desc and assign model'
            let categoryToEdit = this.categories.find(x => x.id == id)
            this.postData.name = categoryToEdit.name
            this.postData.description = categoryToEdit.description
            this.postData.id = id

            //show modal
            this.modals.createCategory = true
            this.editing = true



        },
        updateCategory() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.put(api_url + '/categories/' + this.postData.id, this.postData, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                        this.getCategories()
                        this.modals.createCategory = false

                    }


                })
        },
        deleteCategory(id) {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            //send request
            this.$http.delete(api_url + '/categories/' + id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    this.getCategories()
                })

        },

        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        }

    },
    created() {
        this.getCategories()
    }

}

</script>
<style>
.el-table th.el-table__cell {
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #1e1e2c;
}
</style>
<style scoped>
.form-control {
    color: #000;
}
</style>