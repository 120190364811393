<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-12">
        </div>
        <div class="col-sm-12">

          <p>Select location on map</p>
          <div id="map" class="full-screen-map"></div>

        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui';
import { api_url,datumMixin } from '../../../env';
import { API_KEY } from "../../../API_KEY";
import { Loader, LoaderOption } from "google-maps";

const loader = new Loader(API_KEY);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [datumMixin],
  data() {
    return {
      address: '',
      longitude: '',
      latitude: '',
      country: '',
      countryOptions: [
        'Kenya',
        'Germany',
        'Netherlands',
        'USA',
        'UK',
        'New Zealand'
      ]

    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getUser() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };

      this.$http.get(api_url + '/user', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (!response.ok) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.user = response.user[0]
              let location_details = JSON.parse(response.user.location_details)


              this.street = location_details.street_name
              this.country = location_details.country
              this.city = location_details.city
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
        })
    },
    addLocation() {


      //validate
      if (this.address == '') {
        this.alertNotification("top", "right", "danger", "Please select a location on the map")
        return
      }
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };


      var data = {
        'address': this.address,
        'latitude': this.latitude,
        'longitude': this.longitude
      }

      this.$http.post(api_url + '/models/addLocationDetails', data, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (!response.ok) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              this.alertNotification("top", "right", "success", response.message)
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }


        })
    },
  
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude
          this.longitude = position.coords.longitude
          this.exploreMap(true)

        }, this.exploreMap(false));
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },

    async exploreMap(locationPermission = true) {
      if (!locationPermission) {
        // set default coords to nairobi
        this.latitude = -1.286389;
        this.longitude = 36.817223;

      }
      var vm = this;


      loader.load().then(function (google) {


        // Nearby Map
        const NearbyLatLong = new google.maps.LatLng(vm.latitude, vm.longitude);
        const nearByMapOptions = {
          zoom: 13,
          center: NearbyLatLong,
          scrollwheel: true, // we disable de scroll over the map, it is a really annoing when you scroll through page
          disableDefaultUI: true, // a way to quickly hide all controls
          zoomControl: true,
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8ec3b9'
                }
              ]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1a3646'
                }
              ]
            },
            {
              featureType: 'administrative.country',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878'
                }
              ]
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#64779e'
                }
              ]
            },
            {
              featureType: 'administrative.province',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878'
                }
              ]
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#334e87'
                }
              ]
            },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  visibility: 'off',
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  visibility: 'off',
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [
                {
                  visibility: "off",

                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#304a7d'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c6675'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#9d2a80'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#9d2a80'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#b0d5ce'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#283d6a'
                }
              ]
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3a4762'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#0e1626'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#4e6d70'
                }
              ]
            }
          ]
        };

        const nearByMap = new google.maps.Map(
          document.getElementById("map"),
          nearByMapOptions
        );

        //handle click event on the map
        google.maps.event.addListener(nearByMap, 'click', async function (event) {

          vm.latitude= event.latLng.lat()
          vm.longitude= event.latLng.lng()
          vm.address = await vm.reverseGeocode(vm.latitude, vm.longitude)
         

          vm.alertNotification("top", "right", "success", "Your location will be saved as "+vm.address+" Click  next  to  proceed")
         
   

        });

      });
    },
    async reverseGeocode(lat, lng) {
      try {
        // Load Google Maps API with the Geocoder library
        const google = await loader.load();

        // Create a LatLng object
        const latLng = new google.maps.LatLng(lat, lng);

        return new Promise((resolve, reject) => {
          // Perform reverse geocoding
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
              const address = results[0].formatted_address;
              console.log('Reverse Geocoding Result:', address);
              resolve(address);
            } else {
              console.error('Reverse Geocoding failed:', status);
              reject(new Error('Reverse Geocoding failed'));
            }
          });
        });
      } catch (error) {
        console.error('Error loading Google Maps API:', error);
        throw new Error('Error loading Google Maps API');
      }
    },

    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        this.addLocation()
        return res;
      });
    },
  },
  created() {
    this.getUser()
    this.getUserLocation()

  }
};
</script>
<style></style>
  