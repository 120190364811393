<template>
  <div class="container">
    <div class="row">

      <div class="col-md-7 mr-auto">
        <card>


          <tabs type="primary">
            <tab-pane label="Users">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createAccount(4))">
                  <card class="card-register card-white">
                    <template slot="header">
                      <img class="card-img" src="img/card-primary.png" alt="Card image" />
                      <h4 class="card-title">Register</h4>
                    </template>

                    <ValidationProvider name="fist_name" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="first_name" placeholder="First Name"
                        addon-left-icon="tim-icons icon-single-02" type="text" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider name="last_name" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="last_name" placeholder="Last Name"
                        addon-left-icon="tim-icons icon-single-02" type="text" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="email" placeholder="Email" addon-left-icon="tim-icons icon-email-85"
                        type="email" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="password" placeholder="Password"
                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="password_confirmation" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="password_confirmation" placeholder=" Confirm Password"
                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <base-checkbox class="text-left">
                      I agree to the <a href="#something">terms and conditions</a>.
                    </base-checkbox>

                    <base-button  native-type="submit" slot="footer" type="primary" round block
                      size="lg">
                      Create Account
                    </base-button>
                  </card>
                </form>
              </ValidationObserver>
            </tab-pane>

            <tab-pane label="Stars">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createAccount(3))">
                  <card class="card-register card-white">
                    <template slot="header">
                      <img class="card-img" src="img/card-primary.png" alt="Card image" />
                      <h4 class="card-title">Register</h4>
                    </template>

                    <ValidationProvider name="fist_name" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="first_name" placeholder="First Name"
                        addon-left-icon="tim-icons icon-single-02" type="text" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="middle_name" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="middle_name" placeholder="Middle Name"
                        addon-left-icon="tim-icons icon-single-02" type="text" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="last_name" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="last_name" placeholder="Last Name"
                        addon-left-icon="tim-icons icon-single-02" type="text" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="email" placeholder="Email" addon-left-icon="tim-icons icon-email-85"
                        type="email" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="password" placeholder="Password"
                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="password_confirmation" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="password_confirmation" placeholder=" Confirm Password"
                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <base-checkbox class="text-left">
                      I agree to the <a href="#something">terms and conditions</a>.
                    </base-checkbox>

                    <base-button  native-type="submit" slot="footer" type="primary" round block
                      size="lg">
                      Create Account
                    </base-button>
                  </card>
                </form>
              </ValidationObserver>
            </tab-pane>

            <tab-pane label="Agencies">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createAccount(2))">
                  <card class="card-register card-white">
                    <template slot="header">
                      <img class="card-img" src="img/card-primary.png" alt="Card image" />
                      <h4 class="card-title">Register</h4>
                    </template>

                    <ValidationProvider name="agency_name" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="agency_name" placeholder="Agency Name"
                        addon-left-icon="tim-icons icon-single-02" type="text" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="email" placeholder="Email" addon-left-icon="tim-icons icon-email-85"
                        type="email" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="password" placeholder="Password"
                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <ValidationProvider name="password_confirmation" rules="required" v-slot="{ passed, failed, errors }">
                      <base-input required v-model="password_confirmation" placeholder=" Confirm Password"
                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <base-checkbox class="text-left">
                      I agree to the <a href="#something">terms and conditions</a>.
                    </base-checkbox>

                    <base-button  native-type="submit" slot="footer" type="primary" round block
                      size="lg">
                      Create Account
                    </base-button>
                  </card>
                </form>
              </ValidationObserver>
            </tab-pane>
          </tabs>
        </card>


      </div>

      <div class="col-md-5 ml-auto">
        <img src="/img/datum/purple_logo1.png" height="100">

        <div class="info-area info mt-5 mb-3">
          <div class="icon icon-warning">
            <i class="tim-icons icon-square-pin"></i> Escorts
          </div>

        </div>
        <div class="info-area info mb-3">
          <div class="icon icon-primary">
            <i class="tim-icons icon-satisfied"></i> Hook Ups
          </div>
          <div class="description">

          </div>
        </div>
        <div class="info-area info mb-3">
          <div class="icon icon-info">
            <i class="tim-icons icon-video-66"></i> Premium Content
          </div>

        </div>
        <div class="info-area info">
          <div class="icon icon-danger">
            <i class="tim-icons icon-heart-2"></i> Dating
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { BaseCheckbox } from 'src/components';
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { TabPane, Tabs } from 'src/components';
import { api_url,datumMixin } from "../../env"



extend("email", email);
extend("required", required);

export default {
  mixins : [datumMixin],

  components: {
    BaseCheckbox,
    TabPane,
    Tabs
  },
  data() {
    return {
      first_name: '',
      middle_name: '',
      last_name: '',
      agency_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      api_url : api_url

    };
  },
  methods: {

    createAccount(user_type) {
      const payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        agency_name: this.agency_name,
        user_type: user_type,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      }

      this.$http.post(api_url + '/register',payload)
        .then(response => {
          return response.json()
        }, response => {
          return false
        })
        .then(data => {
          if (!data) {
            this.alertNotification("bottom","right","warning","An error occured, please try again later")
            return
          }
          if(data["status"] != "true"){
            this.alertNotification("top","right","danger",data["message"])
            this.$router.push('login')
          }else{
            this.alertNotification("top","right","success",data["message"])
            return
          }
          


          this.posters = data["posters"]
        })
    },
 
  }
};
</script>
<style>
.card .alert {
  position: relative !important;
  width: 100%;
}

.modal-body, .modal-footer {
  padding: 24px 24px 16px 24px;
}

</style>  