<template>
    <div>

        <div class="row">
            <div class="col-md-6">
                <card>
                    <!-- replace with post image -->
                    <img slot="image" class="card-img-top" :src="post.media.path" alt="Card image cap" />

                </card>
            </div>
            <!-- have a list of comments for that post -->
            <div class="col-md-6">
                <card title="Comments">
                    <ul class="instagram-comments">
                        <li v-for="comment in comments" :key="comment.id" class="comment-item">
                            <div class="comment-content">
                                <span class="username">{{ comment.user.first_name }}</span>
                                <span class="message">{{ comment.message }}</span>
                            </div>
                        </li>
                    </ul>


                    <!-- also a text area to type in my comment -->
                    <h4 slot="header" class="card-title">Comments</h4>

                    <form class="form-horizontal">
                        <div class="row">
                            <div class="col-md-9">
                                <input Id="commentBox" class="form-control" v-model="comment"
                                    placeholder="Enter comment here..." required>
                            </div>
                            <div class="input-group-prepend">
                                <button class="input-group-text" type="submit"  @click="addComment()">
                                    <i class="tim-icons icon-send"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </card>
            </div>
        </div>

    </div>
</template>
<script>
import { TabPane, Tabs } from 'src/components';
import { api_url, datumMixin } from '../../env';

export default {
    name: "PostComments",
    mixins: [datumMixin],
    components: {
        TabPane,
        Tabs
    },
    data() {
        return {
            id: 0,
            post: {},
            comments: [],
            comment: ''
        };
    },
    methods: {
        // I need to get the post to comment on
        getPost() {

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/posts/' + this.id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })

                .then(response => {

                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {

                            this.post = response.post

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        // Get all comments for my post
        getPostComments() {

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/post/comments?post_id=' + this.id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            
                            this.comments = response.comments

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        // Add Comment
        addComment() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",

            };

            var formdata = new FormData();
            formdata.append("message", this.comment);
            formdata.append("post_id", this.id);

            this.$http.post(api_url + '/post/comment', formdata, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    console.log(response)
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {

                            // refresh the comment list
                            this.getPostComments()
                            //clear the comment input field
                            this.comment = ''
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }


                })
        },



    },
    created() {
        this.id = this.$route.params.post_id;
        this.getPost();
        this.getPostComments();
    }
};
</script>
<style lang="scss">
.profile {
    display: table-row;
}

.profile b {
    display: table-cell;
    padding-right: 1em;

}

/* CSS Styles to mimic Instagram comment appearance */
.instagram-comments {
    list-style: none;
    padding: 0;
}

.comment-item {
    margin-bottom: 10px;
}

.comment-content {
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.username {
    font-weight: bold;
}

.message {
    margin-top: 5px;
    font-size: 0.9em;
    // font-style: italic;
}
</style>
  