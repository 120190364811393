<template>
    <div class="col-12">
        <h2 class="card-title">Create Post</h2>

        <div class="row">

            <div class="col-md-7 col-12 mb-auto">
                <card>

                    <h4 class="card-title">Select Media</h4>
                    <image-upload @change="onImageChange" select-text="Select Image/Video" style="width: 100% ;" />

                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-12">
                            <h4>Select Post Type</h4>
                            <el-select class="select-primary" size="large" placeholder="Single Select"
                                v-model="postData.post_type">
                                <el-option v-for="option in selects.postTypes" class="select-primary" :value="option.value"
                                    :label="option.label" :key="option.label">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="col-md-6 col-lg-6 col-12">
                            <h4>Caption</h4>
                            <base-input v-model="postData.caption" placeholder="Enter Caption"> </base-input>
                        </div>
                    </div>



                </card>
            </div>
            <div class="col-md-5 col-12">

                <card>
                    <tabs type="primary">
                        <tab-pane label="Categories">
                            <div class="d-flex flex-wrap">
                                    <base-checkbox class="p-2" v-for="category in categories" v-model="postData.categories[category.id]">{{category.name}}</base-checkbox>                      
                            </div>


                        </tab-pane>

                        <tab-pane label="Visibility">
                            <h4>Select Visibility</h4>
                            <el-select class="select-primary" size="large" placeholder="Single Select"
                                v-model="postData.publish_type">
                                <el-option v-for="option in selects.visibilityTypes" class="select-primary"
                                    :value="option.value" :label="option.label" :key="option.label">
                                </el-option>
                            </el-select>

                            <div v-if="postData.publish_type == 3">
                                <h4>Price Per post</h4>
                                <base-input v-model="postData.pricePerPost" type="number" placeholder="Price Per Post">
                                </base-input>

                            </div>

                        </tab-pane>


                    </tabs>
                </card>
            </div>
        </div>
        <base-button class="mt-3" native-type="submit" type="primary" @click="addPost">Submit</base-button>
    </div>
</template>

<script>


import { Select, Option } from 'element-ui';
import {
    ImageUpload,
} from 'src/components/index';
import { TabPane, Tabs } from 'src/components';
import { api_url } from '../../env';


export default {
    name:"CreatePost",
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        ImageUpload,
        TabPane,
        Tabs,
    },
    data() {
        return {
            selects: {
                simple: '',
                postTypes: [
                    { value: '1', label: 'Story' },
                    { value: '2', label: 'Feed' },

                ],
                visibilityTypes: [
                    { value: '1', label: 'Teaser / Free' },
                    { value: '2', label: 'Subscribers' },
                    { value: '3', label: 'Priced' },
                ],
            },
            postData: {
                media: "",
                media_type: "1",
                post_type: "",
                caption: "",
                categories: {},
                publish_type: "",
                pricePerPost: 0
            },
            categories:[]
        };
    },
    methods: {

        addPost() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",

            };

            var formdata = new FormData();
            formdata.append("media", this.postData.media);
            formdata.append("media_type", this.postData.media_type);
            formdata.append("post_type", this.postData.post_type);
            formdata.append("caption", this.postData.caption);
            formdata.append("categories", JSON.stringify(this.postData.categories));
            formdata.append("publish_type", this.postData.publish_type);
            formdata.append("pricePerPost", this.postData.pricePerPost);

            this.$http.post(api_url + '/posts', formdata,{headers})
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    console.log(response)
                    if (!response.ok) {
                        if(response.status == 400){
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        }else if(response.status == "true"){
                            console.log(response["message"]);
                            this.alertNotification("top", "right", "success", response.message)
                            //redirect to the explore page after creating the post
                            this.$router.push({ name: 'Explore' });
                        }
                        else{
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                   

                })
        },
        getCategories() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/categories', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.categories = response.categories
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        getFeeders() {

        },
        onImageChange(file) {
            this.postData.media = file;
        },
        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        }

    },
    created()
    {
        this.getCategories()
    }
}

</script>
<style scoped></style>