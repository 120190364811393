<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div>
                <h5 class="info-text">
                    Let's start with the basic information (with validation)
                </h5>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-5">

                        <ValidationProvider name="agencyName" rules="required|min:5" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="agencyName" placeholder="Agency Name"
                                addon-left-icon="tim-icons icon-single-02" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                            <base-input disabled required v-model="email" placeholder="Email"
                                addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <ValidationProvider name="phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="phoneNumber" placeholder="Phone(Not shown to public)"
                                addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-5">
                        <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="firstName" placeholder="Contact Person First Name"
                                addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="lastName" placeholder="Contact Person Last Name"
                                addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-10">
                        <label>Display Picture (Shown on Escorts Page)</label>
                        <image-upload @change="onImageChange" select-text="Select Image" :src="src" style="width: 100% ;" />
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>

import { extend, validate } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { api_url } from '../../../env';

import {
    ImageUpload,

} from 'src/components/index';

extend("email", email);
extend("required", required);
extend("numeric", numeric);


export default {
    name: "AccountSettingsAgency",
    components: {
        ImageUpload,
        [DatePicker.name]: DatePicker,
    },
    data() {
        return {
            agencyName: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            profile_image: '',
            src: '',

        };
    },
    methods: {
        validate() {

            return this.$refs.form.validate().then(res => {
                this.$emit("on-validated", res);
                this.addAccountDetails()
                return res;
            });
        },

        getUser() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/user', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            response.user = response.user[0]
                            this.agencyName = response.user["agency_name"]
                            this.firstName = response.user["first_name"]
                            this.lastName = response.user["last_name"]
                            this.email = response.user["email"]
                            let accountDetails = JSON.parse(response.user.agency_details)

                            this.phoneNumber = accountDetails.mobile
                            this.src = accountDetails.profile_path
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },

        addAccountDetails() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",

            };

            var formdata = new FormData();
            formdata.append("first_name", this.firstName);
            formdata.append("last_name", this.lastName);
            formdata.append("agency_name", this.agencyName);
            formdata.append("email", this.email);
            formdata.append("mobile", this.phoneNumber);
            formdata.append("profile_image", this.profile_image);

            this.$http.post(api_url + '/agent/addAccountDetails', formdata, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    console.log(response)
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            console.log(response["message"]);


                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }


                })
        },
        onImageChange(file) {
            this.profile_image = file;
        },
        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        }

    },
    created() {
        this.getUser();
    }
};
</script>
<style></style>
  