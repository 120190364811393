<template>
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-4">
        <card>
          <h4 slot="header" class="card-title">Select Range</h4>

          <el-select class="select-primary" size="large" @change="getAnalyticsData('select')" placeholder="Select Ranges"
            v-model="rangeValue">
            <el-option v-for="option in selects.ranges" class="select-primary" :value="option.value" :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>

        </card>
      </div>

      <div class="col-md-3" v-if="showDatePicker">
        <card>
          <h4 slot="header" class="card-title">Start Date</h4>
          <base-input>
            <el-date-picker type="date" placeholder="Date Picker" v-model="startDate">
            </el-date-picker>
          </base-input>
        </card>
      </div>

      <div class="col-md-3" v-if="showDatePicker">
        <card>
          <h4 slot="header" class="card-title">End Date</h4>
          <base-input>
            <el-date-picker type="date" placeholder="Date Picker" v-model="endDate">
            </el-date-picker>
          </base-input>
        </card>
      </div>

      <div class="col-md-2" v-if="showDatePicker">
        <card>
          <h4 slot="header" class="card-title">Search</h4>
          <!-- button -->
          <button @click="getAnalyticsData('search')" class="btn btn-link">
            <i class="tim-icons icon-zoom-split"></i>
          </button>
        </card>
      </div>

</div>
<div class="row">
  <!-- Stats Cards -->
  <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.title">
          <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type" :icon="card.icon">
            <div slot="footer" v-html="card.footer"></div>
          </stats-card>
        </div>

        <!-- Small charts -->
</div>
      

<div class="row">
  <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Posts</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-tap-02 text-info "></i> {{ totalAmounts }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%" ref="amountsTransactedChart" :chart-data="amountsTransactedChart.chartData"
              :gradient-colors="amountsTransactedChart.gradientColors"
              :gradient-stops="amountsTransactedChart.gradientStops" :extra-options="amountsTransactedChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Chat Activity</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-send text-success "></i> {{ totalMessages }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%" ref="messagesChart" :chart-data="messagesChart.chartData"
              :gradient-colors="messagesChart.gradientColors" :gradient-stops="messagesChart.gradientStops"
              :extra-options="messagesChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6">
        <card class="card" :header-classes="{ 'text-right': isRTL }">
          <h5 slot="header" class="card-title">Top Models(Posts)</h5>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <th class="text-white">#</th>
                  <th class="text-white">Name</th>
                  <th class="text-white">Mobile</th>
                  <th class="text-white">Total Amount  Tipped</th>
                  <th class="text-white">Actiont</th>


                </tr>
                <tr v-for="model in topModelsPosts">
                  <td class="text-center">
                    <div class="photo"><img :src="model.profile_image_path" alt="photo" /></div>
                  </td>
                  <td>{{ model.name }}</td>
                  <td>{{ model.email }}</td>
                  <td>{{ model.total_posts }}</td>
                  <td><a href="" class="btn btn-primary">Start  Chat</a></td>
                </tr>

              </tbody>
            </table>
          </div>
        </card>
      </div>
      <div class="col-lg-6">
        <card class="card" :header-classes="{ 'text-right': isRTL }">
          <h5 slot="header" class="card-title">Top Models(Likes)</h5>
          <table class="table">
            <tbody>
              <tr>
                <th class="text-white">#</th>
                <th class="text-white">Name</th>
                <th class="text-white">Mobile</th>
                <th class="text-white">Total Likes</th>
                <th class="text-white">Action</th>



              </tr>
              <tr v-for="model in topModelsLikes">
                <td class="text-center">
                  <div class="photo"><img :src="model.profile_image_path" alt="photo" /></div>
                </td>
                <td>{{ model.name }}</td>
                <td>{{ model.email }}</td>
                <td>{{ model.total_likes }}</td>
                <td><a href="" class="btn btn-primary">Start  Chat</a></td>
              </tr>

            </tbody>
          </table>
        </card>
      </div>
</div>

    


    </div>
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import TaskList from './TaskList';
import UserTable from './UserTable';
import CountryMapCard from './CountryMapCard';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { api_url } from '../../env';


let postsChartLabels = []
let postsChartData = []
let amountsTransactedChartData = []
let amountsTransactedChartLabels = []
let chatMessagesChartData = []
let chatMessagesChartLabels = []


let smallChartsDatasetOptions = {
  label: 'Data',
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}




export default {
  components: {
    LineChart,
    BarChart,
    StatsCard,
    TaskList,
    CountryMapCard,
    UserTable,
    DatePicker,
    Select,
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,

  },
  data() {
    return {

      // cards


      selects: {
        simple: '',
        ranges: [
          { value: 'Today', label: 'Today' },
          { value: 'Yesterday', label: 'Yesterday' },
          { value: 'LastWeek', label: 'Last Week' },
          { value: 'WeekToDate', label: 'Week To Date' },
          { value: 'LastMonth', label: 'Last Month' },
          { value: 'MonthToDate', label: 'Month To Date' },
          { value: 'ThisYear', label: 'This Year' },
          { value: 'LastYear', label: 'Last Year' },
          { value: 'Custom', label: 'Custom Range' },
        ]
      },
      totalPosts: 0,
      totalAmounts: 0,
      totalMessages: 0,
      showDatePicker: false,
      rangeValue: '',
      startDate: Date.now(),
      endDate: Date.now(),
      statsCards: [
      {
          title: '0',
          subTitle: 'Total Models',
          type: 'secondary',
          icon: 'tim-icons icon-single-02',

        },
        
        {
          title: '0',
          subTitle: 'Total Posts By Models',
          type: 'primary',
          icon: 'tim-icons icon-image-02',

        },
        {
          title: '0',
          subTitle: 'Total Likes',
          type: 'info',
          icon: 'tim-icons icon-tap-02',

        }, 
        {
          title: '0',
          subTitle: 'Total Amount Transacted by Models',
          type: 'info',
          icon: 'tim-icons icon-tap-02',

        }, 



      ],
  
      postsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: postsChartLabels,
          datasets: [
            {
              ...smallChartsDatasetOptions,
              data: postsChartData
            }

          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0]
      },
      amountsTransactedChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: amountsTransactedChartLabels,
          datasets: [
            {
              ...smallChartsDatasetOptions,
              data: amountsTransactedChartData
            }

          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0]
      },
      messagesChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: chatMessagesChartLabels,
          datasets: [
            {
              ...smallChartsDatasetOptions,
              data: chatMessagesChartData
            }

          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0]
      },
      topModelsLikes: [],
      topModelsPosts: []

    };
  },
  watch: {
    rangeValue: function (val) {
      if (val === 'Custom') {
        this.showDatePicker = true;
      } else {
        this.showDatePicker = false;
      }
    }
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [{ name: 'Models', icon: 'tim-icons icon-single-02' }, { name: 'Agents', icon: 'tim-icons icon-gift-2' }, { name: 'Consumers', icon: 'tim-icons icon-tap-02' }];
    }
  },
  methods: {
    

    InitPostsChart() {
      let chartData = {
        datasets: [{
          ...smallChartsDatasetOptions,
          data: postsChartData
        }],
        labels: postsChartLabels
      };
      this.$refs.postsChart.updateGradients(chartData);
      this.postsChart.chartData = chartData;
    },

    InitChatMessagesChart() {
      let chartData = {
        datasets: [{
          ...smallChartsDatasetOptions,
          data: chatMessagesChartData
        }],
        labels: chatMessagesChartLabels
      };
      this.$refs.messagesChart.updateGradients(chartData);
      this.messagesChart.chartData = chartData;
    },
    InitAmountsTransactedChart() {
      let chartData = {
        datasets: [{
          ...smallChartsDatasetOptions,
          data: amountsTransactedChartData
        }],
        labels: amountsTransactedChartLabels
      };
      this.$refs.amountsTransactedChart.updateGradients(chartData);
      this.amountsTransactedChart.chartData = chartData;
    },

    //source here is  used to differentiate source of the click event. If it is from the range select picker or the search button
    getAnalyticsData(source) {
      console.log(this.rangeValue);
      let startDate;
      let endDate = new Date();
      //range selection
      let range = this.rangeValue;

      if (range === 'Today') {
        startDate = new Date();
        endDate = new Date();
      } else if (range === 'Yesterday') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      } else if (range === 'LastWeek') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      }
      else if (range === 'WeekToDate') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date();
      }
      else if (range === 'LastMonth') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      }
      else if (range === 'MonthToDate') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date();
      }
      else if (range === 'ThisYear') {

        let currentDate = new Date();
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date();
      }
      else if (range === 'LastYear') {
        //start  of january last year to end  of december last year
        let currentDate = new Date();
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        endDate = new Date(currentDate.getFullYear() - 1, 11, 31);
      }



      else if (range === 'Custom' && source === 'search') {
        startDate = this.startDate;
        endDate = this.endDate;
      }
      else if (range === 'Custom' && source === 'select') {
        return;
      }
      else {
        return;
      }

      //format date
      const dateFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
      startDate = dateFormatter.format(startDate);
      endDate = dateFormatter.format(endDate);


      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };

      this.$http.get(api_url + '/analytics/admin?start_date=' + startDate + '&end_date=' + endDate, { headers })
        .then((response) => {
          return response.json();
        }).then((data) => {
          data = data.data;
          // signups
          usersChartLabels = []
          let subscriber_signups = data.subscriber_signups;
          let model_signups = data.model_signups;
          let agent_signups = data.agent_signups;

          //empty data first
          usersChartData = [[], [], []];
          postsChartData = [];
          postsChartLabels = [];
          amountsTransactedChartData = [];
          amountsTransactedChartLabels = [];
          chatMessagesChartData = [];
          chatMessagesChartLabels = [];


          //create list of model signups
          let model_signups_list = [];
          for (let i = 0; i < model_signups.length; i++) {
            let model_signup = model_signups[i];
            model_signups_list.push(model_signup.total_items);
            usersChartLabels.push(model_signup.key_);
          }

          usersChartData[0] = model_signups_list;

          //create list of agent signups
          let agent_signups_list = [];
          for (let i = 0; i < agent_signups.length; i++) {
            let agent_signup = agent_signups[i];
            agent_signups_list.push(agent_signup.total_items);
          }
          usersChartData[1] = agent_signups_list;

          //create list of subscriber signups
          let subscriber_signups_list = [];
          for (let i = 0; i < subscriber_signups.length; i++) {
            let subscriber_signup = subscriber_signups[i];
            subscriber_signups_list.push(subscriber_signup.total_items);
          }
          usersChartData[2] = subscriber_signups_list;

          console.log(usersChartData);
          //update big chart
          this.InitUsersChart(0);

          //payments
          this.statsCards[0].title = "100";
          this.statsCards[1].title = "100";
          this.statsCards[2].title = "100";
          this.statsCards[3].title = "100";

          //signups
          this.statsCards[4].title = (data.aggregates.signups == 0) ? "0" : data.aggregates.signups;
          this.statsCards[5].title = (data.aggregates.model_signups == 0) ? "0" : data.aggregates.model_signups;
          this.statsCards[6].title = (data.aggregates.agent_signups == 0) ? "0" : data.aggregates.agent_signups;
          this.statsCards[7].title = (data.aggregates.subscriber_signups == 0) ? "0" : data.aggregates.subscriber_signups;

          //posts
          this.statsCards[8].title = (data.aggregates.posts == 0) ? "0" : data.aggregates.posts;
          this.statsCards[9].title = (data.aggregates.posts_stories == 0) ? "0" : data.aggregates.posts_stories;
          this.statsCards[10].title = (data.aggregates.posts_feed == 0) ? "0" : data.aggregates.posts_feed;
          let videos = (data.aggregates.posts_videos == 0) ? "0" : data.aggregates.posts_videos;
          let images = (data.aggregates.posts_images == 0) ? "0" : data.aggregates.posts_images;
          this.statsCards[11].title = videos + " | " + images;

          this.totalPosts = data.aggregates.posts;
          this.totalAmounts = 0;//data.aggregates.amounts_transacted;
          this.totalMessages = data.aggregates.messages;//data.aggregates.amounts_transacted;

          //interactions
          let total_interactions = data.aggregates.comments + data.aggregates.likes + data.aggregates.bookmarks;
          this.statsCards[12].title = (total_interactions == 0) ? "0" : total_interactions;
          this.statsCards[13].title = (data.aggregates.likes == 0) ? "0" : data.aggregates.likes;
          this.statsCards[14].title = (data.aggregates.comments == 0) ? "0" : data.aggregates.comments;
          this.statsCards[15].title = (data.aggregates.bookmarks == 0) ? "0" : data.aggregates.bookmarks;


          //Small charts
          //posts
          postsChartLabels = [];
          postsChartData = [];
          let posts = data.posts;
          for (let i = 0; i < posts.length; i++) {
            let post = posts[i];
            postsChartLabels.push(post.key_);
            postsChartData.push(post.total_items);
          }

          this.InitPostsChart()

          //amounts transacted
          amountsTransactedChartLabels = [];
          amountsTransactedChartData = [];
          // let amounts_transacted = data.amounts_transacted;
          // for (let i = 0; i < amounts_transacted.length; i++) {
          //   let amount_transacted = amounts_transacted[i];
          //   amountsTransactedChartLabels.push(amount_transacted.key_);
          //   amountsTransactedChartData.push(amount_transacted.total_items);
          // }
          this.InitAmountsTransactedChart()

          //chat messages
          chatMessagesChartLabels = [];
          chatMessagesChartData = [];
          let chat_messages = data.messages;
          for (let i = 0; i < chat_messages.length; i++) {
            let chat_message = chat_messages[i];
            chatMessagesChartLabels.push(chat_message.key_);
            chatMessagesChartData.push(chat_message.total_items);
          }
          this.InitChatMessagesChart()


          let topModelsLikes = data.top_models_likes
          topModelsLikes.forEach(model => {
            let personal_details = JSON.parse(model.personal_details)

            let name = model.first_name + " " + model.last_name + "(" + personal_details.nick_name + ")";
            let profile_image_path = personal_details.profile_path
            let email = model.email
            //let profile_page_link = 

            this.topModelsLikes.push({
              name: name,
              profile_image_path: profile_image_path,
              email: email,
              total_likes: model.total_likes
            })

            let topModelsPosts = data.top_models_posts
            topModelsPosts.forEach(model => {
              let personal_details = JSON.parse(model.personal_details)

              let name = model.first_name + " " + model.last_name + "(" + personal_details.nick_name + ")";
              let profile_image_path = personal_details.profile_path
              let email = model.email
              //let profile_page_link = 

              this.topModelsPosts.push({
                name: name,
                profile_image_path: profile_image_path,
                email: email,
                total_posts: model.total_posts
              })

            });


          });







        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
    //this.InitUsersChart(0);
    this.getAnalyticsData("search");
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style scoped></style>
    