<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard>
          <template slot="header">
            <h3 class="card-title">Agency Profiles</h3>
            <h3 class="description">
              Infomation includes
            </h3>
          </template>



          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-settings-gear-63"></i>
              <p>Account</p>
            </template>
            <account-settings-agency ref="step1" @on-validated="onStepValidated"></account-settings-agency>

          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-delivery-fast"></i>
              <p>Address</p>
            </template>

            <address-agency ref="step1" @on-validated="onStepValidated"></address-agency>

          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-money-coins"></i>
              <p>Payment Details</p>
            </template>

            <payment-details-agency ref="step1" @on-validated="onStepValidated"></payment-details-agency>

          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>

import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';
import AccountSettingsAgency from './AccountSettingsAgency.vue';
import AddressAgency from './AddressAgency.vue'
import PaymentDetailsAgency from './PaymentDetailsAgency.vue';

export default {
  data() {
    return {
      wizardModel: {}
    };
  },
  components: {
    AccountSettingsAgency,
    AddressAgency,
    PaymentDetailsAgency,
    SimpleWizard,
    WizardTab
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      swal('Good job!', 'You clicked the finish button!', 'success');
    }
  }
};
</script>
  