<template>
    <div class="col-12">
        <h2 class="card-title">Models</h2>
        <card>
            <div class="row">

                <div class="col-md-12 col-12 mb-auto">



                    <el-table header-cell-class-name="table" header-row-class-name="table-transparent"
                        row-class-name="table-transparent" :data="models">
                        <el-table-column min-width="150" sortable label="Profile Picture"
                            property="personal_details.profile_path">
                            <template slot-scope="scope">
                                <img v-if="scope.row.personal_details.profile_path" class="card-img-top"
                                    :src="scope.row.personal_details.profile_path" alt="Model Image Profile"
                                    :style="{ maxWidth: '150px', maxHeight: '130px' }" />
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150" sortable label="Name" property="first_name"></el-table-column>
                        <el-table-column min-width="150" sortable label="E-mail" property="email"></el-table-column>
                        <el-table-column min-width="150" sortable label="Phone Number"
                            property="personal_details.mobile"></el-table-column>
                        <el-table-column min-width="150" sortable label="Age">
                            <template slot-scope="scope">
                                <span>{{ calculateAge(scope.row.personal_details.date_of_birth) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150" sortable label="Action">
                            <div slot-scope="scope" class="">
                                <a @click="changeModelStatus(scope.row.user_id, 'active')" class="btn btn-primary"
                                    v-if="scope.row.active == 0">Approve</a>

                                <a @click="changeModelStatus(scope.row.user_id, 'inactive')" class="btn btn-danger"
                                    v-else>Reject</a>
                            </div>
                        </el-table-column>



                    </el-table>




                </div>
            </div>

            <!-- Classic Modal -->

        </card>
    </div>
</template>

<script>


import { Option, Table, TableColumn } from 'element-ui';
import { TabPane, Tabs, Modal } from 'src/components';
import { api_url, datumMixin } from '../../env';

export default {
    name: "ManageAllModels",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    mixins: [datumMixin],

    data() {
        return {
            models: []
        };
    },
    methods: {

        getModels() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/models', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            response.models.forEach(model => {
                                let location_details = (model.location_details) ? JSON.parse(model.location_details) : null
                                let personal_details = (model.personal_details) ? JSON.parse(model.personal_details) : null
                                let payment_details = (model.payment_details) ? JSON.parse(model.payment_details) : null

                                //2 . Prep it
                                model.location_details = location_details
                                model.personal_details = personal_details
                                model.payment_details = payment_details

                            });

                            //3. Assign it to this.model
                            this.models = response.models
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })
        },

        changeModelStatus(id, status) {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.post(api_url + '/admin/user/changeStatus?user_id=' + id + '&status=' + status, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                }).then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.models = response.models
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })

        },
    },
    created() {
        this.getModels()

    }

}

</script>
<style>
.el-table th.el-table__cell {
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #1e1e2c;
}
</style>
<style scoped>
.form-control {
    color: #000;
}

.modal {
    top: -150px;
}

@media screen and (min-width:800px) {
    .modal-dialog {
        max-width: 800px;
    }
}
</style>