<template>
    <div>
      
      
     
        <div>
            <h6>{{title}}</h6>
            <div class="d-flex" style="flex-wrap: wrap;">


                <div class="datum-grid col-lg-2 col-md-2 col-3 mb-1" v-for=" model in models">
                    <a @click="$router.push('/model/' + model.id)" >
                        <div class="card home-cards">
                            <img slot="image" class="card-img-top" :src="model.personal_details.profile_path" @contextmenu="showPopUpImage(model,'model')"
                                alt="Premium Escorts Image" />
                            <div class="card-img-overlay text-white d-flex flex-column justify-content-center">
                                <span class="overlay_text" ><span style="color: green; " >●</span>
                                    {{ model.personal_details.nick_name }} , 
                                    <!-- {{
                                        model.location_details.city.charAt(0).toUpperCase() +
                                        model.location_details.city.slice(1) }} -->
                                        </span>
                                <span class="overlay_text">Age : {{ " " + calculateAge(model.personal_details.date_of_birth)
                                }} years </span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div id="imageModal" class="modal custom-modal" style="display: none;" oncontextmenu="return false;">
            <span class="close">&times;</span>
            <img id="modalImage" src="" alt="Modal Image">
            <div id="caption"></div>
        </div>
    </div>
</template>
<script>
//import VideoPlayer from 'src/components/Video.vue';
import { api_url, datumMixin } from '../../env';
export default {
    name: 'home-page',
    layout: 'default',
    mixins: [datumMixin],


    data() {
        return {
            models:[],
            title: "",
            criteria: ""
        };
    },
    methods: {
      
        getModels() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/models'+this.criteria, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            //steps
                            //1. Get all the data and prep it
                            response.models.forEach(model => {
                               model = this.formatModelDetails(model)

                            });

                            // Assign it to this.model
                            this.models = response.models
                            //only pick models with  allDetails
                            this.models = this.models.filter(model => model.hasAllDetails == true)



                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                },
                )
        },
        showPopUpImage(entity, type)
        {
            const mediaQuery = window.matchMedia('(max-width: 990px)');
            if (!mediaQuery.matches) {
                return  false;

            }

            let image=""
            let entityName=""
            let age=0
            let model_count=0
            let captionText = ""
            let location = ""
            if(type == "agency"){
                image = entity.agency_details.profile_path
                entityName = entity.agency_name
                model_count = entity.count
                
                captionText = "Model Count : " + model_count
                

            }
            else if(type == "model"){
                image = entity.personal_details.profile_path
                entityName = entity.personal_details.nick_name
                age = this.calculateAge(entity.personal_details.date_of_birth)
                location = entity.location_details.city.charAt(0).toUpperCase() +
                                        entity.location_details.city.slice(1) 
                captionText = "Name : "+entity.personal_details.nick_name+"<br> Age : " + age + " years" + " <br> Location :  " + location 
            }
            console.log(captionText)

            if (type == "model")
            {          
                 captionText += "<br> <a class='btn btn-primary' href='/#/model/"+entity.id+"'>View Profile</a>"
            }
            else if (type == "agency")
            {
                captionText += "<br> <a class='btn btn-primary' href='/#/agent/"+entity.user_id+"/"+entity.id+"'>View Profile</a>"
            }
            console.log(captionText)


         
            const modal = document.getElementById("imageModal");
            const modalImage = document.getElementById("modalImage");
            const caption = document.getElementById("caption");

            modal.style.display = "block";
            modalImage.src = image;
            caption.innerHTML = captionText;

            document.querySelector(".close").addEventListener("click", () => {
            modal.style.display = "none";
            });



        },
       



    },
    created() {
        this.title = this.$route.params.title;
        this.criteria = this.$route.params.criteria;
        this.getModels()
        //this.calculateAge(birthDate);

    }

};
</script>
<style >
.datum-grid {
    padding-right: 1px;
    padding-left: 1px;
    padding-top: 1px;
    padding-bottom: 10px;
    justify-content: center;
    padding: auto;
}

/* .card-img-overlay {
    top: 70%;
    background-color: rgba(0, 0, 0, 0.5)
} */
.card-img-overlay {
    /* display: none; */
    top: 0%;
}

.overlay_text {
    display: none;
}



.datum-grid img {
    aspect-ratio: 2/3;
    object-fit:cover;
    mix-blend-mode:exclusion;
}

/* .modalImage{
    aspect-ratio: 1;
    object-fit: contain;
    mix-blend-mode: normal;
} */

.custom-modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 50%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
}

.custom-modal img {
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    max-width: 80%; /* Adjust the size as needed */
    max-height: 80vh; /* Adjust the size as needed */
}

.close {
    position: absolute;
    top: 50px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    color: white;
}

#caption {
    text-align: center;
    color: white;
    padding: 10px;
    max-width: 90%;
    margin: 0 auto; /* Center the caption horizontally */
}

.home-cards{
    background-color: #1e1e2d;
}


@media screen and (min-width: 1201px) {
    .datum-grid {
        /* height: 24.5rem; */
    }

    
    .home-cards:hover .card-img-overlay {
        top: 70%;
        background-color: rgba(0, 0, 0, 0.5);
        /* transition: all 0.3s; Add a transition for smooth effect */
    }
 

    .home-cards:hover .overlay_text {
        display: block;
    }


}

@media screen and (min-width: 991px) and (max-width: 1200px) {
    .datum-grid {
        /* height: 17.5rem; */
    }



}

@media screen and (min-width: 700px) and (max-width: 990px) {
    .datum-grid {
        /* xheight: 14rem; */
    }


}

@media screen and (max-width: 700px) {
    .datum-grid {
        /* height: 7.5rem; */
    }



    .overlay_text {
        font-size: 7px;
        font-weight: bold;
    }
}
</style>
  