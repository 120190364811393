<template>
  <div class="row">

    <div class="col-12">



      <div class="col-md-12">
        <div class="d-flex justify-content-between">
          <div class="p-8 mb-3">
            <!-- <input type="text" placeholder="Enter City or Street" class="form-control" v-model="searchKey" /> -->
            <vue-google-autocomplete class="form-control" id="search-bar" v-on:placechanged="getAddressData" placeholder="Enter Location To Find Models"
               />
          </div>

        </div>

        <div id="map" class="full-screen-map"></div>
      </div>

    </div>


  </div>
</template>
<script>
import { api_url, datumMixin } from '../../env';
import { API_KEY } from "../../API_KEY";
import { Loader, LoaderOption } from "google-maps";
import { TabPane, Tabs } from 'src/components';
import VueGoogleAutocomplete from 'vue-google-autocomplete';


const loader = new Loader(API_KEY);

export default {
  components: {
    TabPane,
    Tabs,
    VueGoogleAutocomplete
  },
  mixins: [datumMixin],
  data() {
    return {
      latitude: 0,
      longitude: 0,
      searchKey: "",
      models: [
        {
          name: "Melisa",
          icon: "http://localhost:8080/img/models/model2.jpg",
          location: { lat: -1.23054, lng: 36.84105 },
          info: "Melisa , 27 yrs , etc"
        },
        {
          name: "MELISSA 2",
          icon: "/img/models/model1.jpg",
          location: { lat: -1.24055, lng: 36.94105 },
          info: "Melisa , 27 yrs , etc"
        },
        {
          name: "MELISSA 2",
          icon: "/img/models/model2.jpg",
          location: { lat: -1.25056, lng: 36.94105 },
          info: "Melisa , 27 yrs , etc"
        },
        {
          name: "MELISSA 2",
          icon: "/img/models/model3.jpg",
          location: { lat: -1.27073, lng: 36.94105 },
          info: "Melisa , 27 yrs , etc"
        },
        {
          name: "MELISSA 2",
          icon: "http://localhost:8080/img/models/model1.jpg",
          location: { lat: -1.27083, lng: 36.84105 },
          info: "Melisa , 27 yrs , etc"
        },
        {
          name: "MELISSA 2",
          icon: "http://localhost:8080/img/models/model2.jpg",
          location: { lat: -1.26093, lng: 36.94105 },
          info: "Melisa , 27 yrs , etc"
        }
      ],
      markers: [],
      searchedADdress: null

    }
  },

  methods: {

    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude
          this.longitude = position.coords.longitude
          this.exploreMap(true)

        }, this.exploreMap(false));
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },

    exploreMap(locationPermission = true) {
      if (!locationPermission) {
        // set default coords to nairobi
        this.latitude = -1.286389;
        this.longitude = 36.817223;

      }
      var vm = this;


      loader.load().then(function (google) {


        // Nearby Map
        const NearbyLatLong = new google.maps.LatLng(vm.latitude, vm.longitude);
        const nearByMapOptions = {
          zoom: 13,
          center: NearbyLatLong,
          scrollwheel: true, // we disable de scroll over the map, it is a really annoing when you scroll through page
          disableDefaultUI: true, // a way to quickly hide all controls
          zoomControl: true,
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8ec3b9'
                }
              ]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1a3646'
                }
              ]
            },
            {
              featureType: 'administrative.country',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878'
                }
              ]
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#64779e'
                }
              ]
            },
            {
              featureType: 'administrative.province',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878'
                }
              ]
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#334e87'
                }
              ]
            },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  visibility: 'off',
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  visibility: 'off',
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [
                {
                  visibility: "off",

                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#304a7d'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c6675'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#9d2a80'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#9d2a80'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#b0d5ce'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#023e58'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d'
                }
              ]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#283d6a'
                }
              ]
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3a4762'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#0e1626'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#4e6d70'
                }
              ]
            }
          ]
        };

        const nearByMap = new google.maps.Map(
          document.getElementById("map"),
          nearByMapOptions
        );

        //handle click event on the map
        google.maps.event.addListener(nearByMap, 'click', function (event) {

          //show explore here button
          //route  to models page
          vm.addMarker(event.latLng, "Explore models here", null, null, nearByMap)
         

        });

      });
    },

    addMarker(coords, title, icon, info, map) {
    //remove previous markers
    this.markers.forEach(marker => marker.setMap(null));


      var marker = new google.maps.Marker({
        position: coords,
        map: map,
        title: title,
        icon: icon
      });
      //add to martkers array

      if (info) {
        var infoWindow = new google.maps.InfoWindow({
          content: info
        })


        
      }
      let vm = this;
      marker.addListener('click', function () {
          vm.navigateToModels(coords)
          vm.navigateToModels(coords.lat(), coords.lng())
      })
      vm.markers.push(marker)


    },

    async navigateToModels(latitude, longitude) 
    {
      
      let address = await this.reverseGeocode(latitude, longitude)
      console.log(address)

      let criteria = '?longitude='+longitude+'&latitude='+latitude+'&take=50';
      let  title = "Models Near "+address
      this.$router.push({ name: 'Models', params: { criteria: criteria, title: title } })
    },
    getPlaceData(place) {
      // Handle the selected place data
      console.log('Place data:', place);
      // You can extract relevant information from the place object, such as place.name, place.formatted_address, etc.
    },

    async reverseGeocode(lat, lng) {
      try {
        // Load Google Maps API with the Geocoder library
        const google = await loader.load();

        // Create a LatLng object
        const latLng = new google.maps.LatLng(lat, lng);

        return new Promise((resolve, reject) => {
          // Perform reverse geocoding
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
              var address = results[0].formatted_address;
              console.log(results)
              //return where types is street_address
              for (let index = 0; index < results.length; index++) {
                const result = results[index];
                if (result.types.includes("route")) {
                  resolve(result.formatted_address)
                }
                else if (result.types.includes("street_address")) {
                  resolve(result.formatted_address)
                }
              
              }
              
              resolve(address);
            } else {
              console.error('Reverse Geocoding failed:', status);
              reject(new Error('Reverse Geocoding failed'));
            }
          });
        });
      } catch (error) {
        console.error('Error loading Google Maps API:', error);
        throw new Error('Error loading Google Maps API');
      }
    },
    getAddressData: function (addressData, placeResultData, id) {
        this.longitude = addressData.longitude;
        this.latitude = addressData.latitude;

        this.navigateToModels(addressData.latitude, addressData.longitude)
      },

  },
  mounted() {


    this.getUserLocation();

    // Load Google Maps Places API script
    // const script = document.createElement('script');
    // script.src = "https://maps.googleapis.com/maps/api/js?key="+API_KEY+"&libraries=places";
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
  }
};
</script>
<style lang="scss">
.card-map {
  min-height: 500px;

  .map {
    height: 450px;
    width: 100%;
  }
}

.list-group-item {
  background-color: transparent;
}

.gm-style .gm-style-iw-c {
  background-color: black;
}
</style>
  