<template>
  <div>
    <h4 class="title pl-3">Select Item</h4>
    <div class="row">
      <div class="col-4">
        <div class="card" @click="$router.push('/categories')">
          <div class="card-body text-center py-5">
            <i class="tim-icons icon-bullet-list-67 text-primary"></i>
            <br>
            <h3>Edit Categories</h3>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card " @click="$router.push('/admin/manageAllModels')">
          <div class="card-body text-center py-5">
            <i class="tim-icons icon-single-02 text-primary"></i>
            <h3>Manage Models</h3>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" @click="$router.push('/admin/manageAgents')">
          <div class="card-body text-center py-5">
            <i class="tim-icons icon-badge text-primary"></i>
            <h3>Manage  Agents</h3>
          </div>
        </div>
      </div>
    
    </div>

  </div>
</template>
<script>
export default {
  name: 'grid-system'
};
</script>
<style scoped>
.card {
  cursor: pointer;
}

</style>
  