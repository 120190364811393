<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div class="d-flex flex-wrap">
                <base-checkbox class="p-2" v-for="category in categories" v-model="selectedCategories[category.id]">{{
                    category.name }}</base-checkbox>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { api_url,datumMixin } from '../../../env';

import {
    ImageUpload,
} from 'src/components/index';

export default {
    name: "EscortServices",
    components: { ImageUpload },
    data() {
        return {
            selectedCategories: {},
            categories: [],
        };
    },
    mixins: [datumMixin],
    methods: {
        validate() {
            return this.$refs.form.validate().then(res => {
                this.$emit("on-validated", res);
                this.addServices()
                return res;
            });
        },
        getCategories() {

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/categories', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.categories = response.categories
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        getUser() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/user', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return false
                })
                .then(response => {
                    console.log(response)
                    if (!response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        }
                     } else if (response.status == "true") {

                            console.log(response.user)
                            this.selectedCategories = JSON.parse(response.user[0].services).categories

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    
                })
        },


        addServices() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            var data = {'categories': this.selectedCategories}

            this.$http.post(api_url + '/models/addServices', data, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }


                })
        },
       
    },
    created() {
        this.getCategories()
        this.getUser()
    }
};
</script>
<style></style>
  