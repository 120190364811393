<template>
    <div class="col-12">
        <h2 class="card-title">Models</h2>
        <base-button type="primary" @click="$router.push('/agents/addModel')">
            Create Model
        </base-button>
        <card>
            <div class="row">

                <div class="col-md-12 col-12 mb-auto">


    <el-table header-cell-class-name="table" header-row-class-name="table-transparent"
                        row-class-name="table-transparent" :data="models">
                        <el-table-column min-width="150" sortable label="Profile Picture"
                            property="personal_details.profile_path">
                            <template slot-scope="scope">
                                <img v-if="scope.row.personal_details.profile_path" class="card-img-top"
                                    :src="scope.row.personal_details.profile_path" alt="Model Image Profile"
                                    :style="{ maxWidth: '150px', maxHeight: '130px' }" />
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150" sortable label="Name" property="first_name"></el-table-column>
                        <el-table-column min-width="150" sortable label="E-mail" property="email"></el-table-column>
                        <el-table-column min-width="150" sortable label="Phone Number"
                            property="personal_details.mobile"></el-table-column>
                        <el-table-column min-width="150" sortable label="Age">
                            <template slot-scope="scope">
                                <span>{{ calculateAge(scope.row.personal_details.date_of_birth) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150" sortable label="Action">
                            <div slot-scope="scope" class="">
                                <a @click="changeModelStatus(scope.row.user_id, 'active')" class="btn btn-primary" v-if="scope.row.active == 0">Approve</a>

                                <a @click="changeModelStatus(scope.row.user_id, 'inactive')" class="btn btn-danger" v-else>Remove</a>
                            </div>
                        </el-table-column>



                    </el-table>




                </div>
            </div>

          
        </card>
    </div>
</template>

<script>


import { Select, Option, Table, TableColumn,DatePicker } from 'element-ui';
import { TabPane, Tabs, Modal, BaseAlert } from 'src/components';
import { api_url } from '../../env';

import {
    ImageUpload,
    
} from 'src/components/index';
import ImageUploadMultiple from '../../components/ImageUploadMultiple.vue';
export default {
    name: "Categories",
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        TabPane,
        Tabs,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        Modal,
        ImageUpload,
        ImageUploadMultiple,
        [DatePicker.name]: DatePicker
    },
    data() {
        return {
            tableData: [
                {
                    id: 1,
                    name: 'Black',
                    description: 'Black people',
                    action: '<a class="btn btn-primary">Edit</a>',
                    city: 'Oud-Turnhout'
                },

            ],
            categories: [],
            postData: {
                id: "",
                name: "",
                description: "",
                categories:{}
            },
            modals: {
                createModel: false,

            },
            models:[]
            
        };
    },
    methods: {

        addModel() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.post(api_url + '/categories', this.postData, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                        this.getCategories()
                        this.modals.createCategory = false

                    }


                })
        },
         getModels() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            let  agent_id = localStorage.getItem('user_id')
            this.$http.get(api_url + '/models?agent_id='+agent_id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            response.models.forEach(model => {
                                let location_details = (model.location_details) ? JSON.parse(model.location_details) : null
                                let personal_details = (model.personal_details) ? JSON.parse(model.personal_details) : null
                                let payment_details = (model.payment_details) ? JSON.parse(model.payment_details) : null

                                //2 . Prep it
                                model.location_details = location_details
                                model.personal_details = personal_details
                                model.payment_details = payment_details

                            });

                            //3. Assign it to this.model
                            this.models = response.models
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })
        },
   

        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        },

         calculateAge(dob) {

            var dateParts = dob.split('/');
            var day = parseInt(dateParts[0]);
            var month = parseInt(dateParts[1]) - 1;
            var year = parseInt(dateParts[2]);

            var today = new Date();
            var birthDate = new Date(year, month, day);
            var age = today.getFullYear() - birthDate.getFullYear();
            var month = today.getMonth() - birthDate.getMonth();

            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        },

    },
    created() {
        this.getModels()
        this.getCategories()
    }

}

</script>
<style>
.el-table th.el-table__cell {
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #1e1e2c;
}
</style>
<style scoped>
.form-control {
    color: #000;
}

.modal{
    top : -150px;
}

@media screen and (min-width:800px) {
.modal-dialog{
    max-width: 800px ;
}
}
</style>