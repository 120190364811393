<template>
    <div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
          <simple-wizard @complete="wizardComplete">
            <template slot="header">
              <h3 class="card-title">Build your profile</h3>
              <h3 class="description">
                Infomation includes you profile picture, personal details for verification, location details and payment details 
              </h3>
            </template>
  
            <wizard-tab :before-change="() => validateStep('step1')">
              <template slot="label">
                <i class="tim-icons icon-single-02"></i>
                <p>About Me</p>
              </template>
              <about-me
              ref="step1"
              @on-validated="onStepValidated"
            ></about-me>
             
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('step2')">
              <template slot="label">
                <i class="tim-icons icon-shape-star"></i>
                <p>Escort Services</p>
              </template>
              <escort-services
              ref="step2"
              @on-validated="onStepValidated"
            ></escort-services>
             
            </wizard-tab>
  
            <wizard-tab :before-change="() => validateStep('step3')">
              <template slot="label">
                <i class="tim-icons icon-square-pin"></i>
                <p>Location</p>
              </template>
              <location-details
              ref="step3"
              @on-validated="onStepValidated"
            ></location-details>
             
            </wizard-tab>
  
            <wizard-tab :before-change="() => validateStep('step4')">
              <template slot="label">
                <i class="tim-icons icon-money-coins"></i>
                <p>Payment Details</p>
              </template>
              <payment-details
              ref="step4"
              @on-validated="onStepValidated"
            ></payment-details>
            </wizard-tab>
          </simple-wizard>
        </div>
      </div>
    </div>
  </template>
  <script>

  import swal from 'sweetalert2';
  import { SimpleWizard, WizardTab } from 'src/components';
  import AboutMe  from './AboutMe.vue';
  import EscortServices from './EscortServices.vue';
  import LocationDetails from './LocationDetails.vue';
  import PaymentDetails from './PaymentDetails.vue';

  export default {
    data() {
      return {
        wizardModel: {}
      };
    },
    components: {
      
      AboutMe,
      EscortServices,
      LocationDetails,
      PaymentDetails,
      SimpleWizard,
      WizardTab,
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate();
      },
      onStepValidated(validated, model) {
        this.wizardModel = { ...this.wizardModel, ...model };
      },
      wizardComplete() {
        console.log("wut")
        swal('Good job!', 'You clicked the finish button!', 'success');
      }
    }
  };
  </script>
  