<template>
  <div class="container" style="overflow: auto;">
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

    <div class="row">
      <div class="col-lg-12 layout-spacing">
        <div class="statbox widget box box-shadow">

          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="d-flex">
                <div id="content_1" class="tabcontent story-area" v-for="story in stories">
                  <div class="story-container-1">
                    <a @click="$router.push('user/storyFullView/' + story.id)">
                      <div class="single-story">
                        <img :src="story.posts[0].path" class="single-story-bg">
                        <div class="story-author">
                          <img :src="story.posts[0].path">
                          <p>{{ story.personal_details.nick_name }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { api_url, datumMixin } from '../../env';

export default {
  name: 'Story',
  mixins: [datumMixin],

  data() {
    return {
      stories: [],
    };
  },
  methods: {
    getStories() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/posts?type=1', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.models.forEach(model => {
                let personal_details = (model.personal_details) ? JSON.parse(model.personal_details) : null
                model.personal_details = personal_details
              });
              
              this.stories = response.models
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },


  },
  created() {
    this.getStories();
  }
};

</script>
<style>
/* body {
  background: #eee;
  margin-top: 20px;
} */

.widget {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%);
}

.widget.box .widget-header {
  background: #fff;
  padding: 0px 8px 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.widget .widget-header {
  border-bottom: 0px solid #f1f2f3;
}

.widget.box .widget-header {
  background: #fff;
  padding: 0px 8px 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.widget .widget-header {
  border-bottom: 0px solid #f1f2f3;
}

.widget .widget-header:after {
  clear: both;
}

.widget .widget-header:before,
.widget .widget-header:after {
  display: table;
  content: "";
  line-height: 0;
}

.widget-content-area {
  padding: 10px;
  position: relative;
  /* background-color: #1e1e2e; */
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.story-container-1 {
  display: flex;
  align-items: center;
  overflow: auto;
  /* justify-content: flex-start; */
}

.story-container-1 .single-create-story {
  height: 175px;
  width: 110px;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #e4e4e4;
  text-align: center;
}

img.single-create-story-bg {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.create-story-author img {
  height: 40px;
  width: 40px;
  margin-top: -20px;
  padding: 4px;
  background: #e4e4e4;
  border-radius: 50%;
}

.story-container-1 .single-story {
  height: 175px;
  width: 110px;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.story-container-1 .single-story::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(rgb(255 0 0 / 0%), black);
}

img.single-story-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.story-container-1 .story-author {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  right: 0px;
  text-align: center;
  z-index: 99;
  cursor: pointer;
}

.story-author img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid white;
  padding: 4px;
}

.story-container-1 .story-author p {
  color: #fff;
  width: 100%;
  margin: 5px 0px 0px 0px;
  font-weight: 600;
  font-size: 12px;
}

.create-story-author p {
  margin: 0px;
  font-size: 13px;
  font-weight: 500;
}
</style>