<template>
    <div>

        <div class="row">
            <div class="col-md-6">
                <card>
                    <img slot="image" class="card-img-top" :src="model.personal_details.profile_path"
                        alt="Card image cap" />

                </card>
            </div>

            <div class="col-md-6">
                <card>
                    <template slot="header">
                        <div class="d-flex justify-content-between">
                            <div class="p2">
                                <h3 class="card-title">{{ model.first_name + " " + model.last_name }} ({{
                                    model.personal_details.nick_name }})</h3>
                            </div>
                            <div class="p2">
                                <a class="nav-link" @click="$router.push('/account/stars')" style="cursor:pointer;">
                                    <i class="tim-icons icon-pencil" id="edit-btn"
                                        style="font-size: medium; color: #fff; " v-if="loggedInUserId==model.user_id"></i>
                                </a>
                            </div>
                        </div>

                    </template>
                    <tabs>
                        <tab-pane label="Profile">

                            <li class="profile">Age <b>: {{ " " + calculateAge(model.personal_details.date_of_birth) }}
                                    years</b>
                            </li>
                            <li class="profile">Bust Size <b>: {{ " " + model.personal_details.bust_size }}</b></li>
                            <li class="profile">Hip Size <b>: {{ " " + model.personal_details.hip_size }}</b></li>
                            <li class="profile">Height <b> : {{ " " + model.personal_details.height }}</b></li>
                            <li class="profile">Hair Colour <b>: {{ " " + model.personal_details.hair_color }}</b></li>
                            <li class="profile">Location <b>: {{ " " + model.location_details.street_name }} ({{
                                model.location_details.city + " " + model.location_details.country }})</b></li>
                            <li class="profile">Availability <b>: {{ " " + model.personal_details.availability }}</b></li>
                            <li class="profile">Languages <b>: {{ " " + model.personal_details.languages }} </b></li>

                        </tab-pane>

                        <tab-pane label="Rates">

                            <li class="profile">Hourly <b>: {{ " " + model.payment_details.hourly_rate }}</b></li>
                            <li class="profile">Overnight <b>:{{ " " + model.payment_details.overnight_rate }}</b></li>

                        </tab-pane>
                        <tab-pane label="Services" class="ml-3">

                            <li class="" v-for="(value, key, index) in model.services.categories">
                                <span v-for="category in categories">
                                    <span v-if="category.id == key">{{ category.name }}</span>
                                </span>
                            </li>
                        </tab-pane>
                        <tab-pane label="Gallery">
                            <div class="d-flex" style="overflow: auto;">


                                <div v-for="post in posts" class="col-lg-3 col-md-3 col-3 mb-1">
                                    <a @click="$router.push('/explore')">
                                        <div class="card">
                                            <img slot="image" class="card-img-top" :src="post.url" alt="Card image cap"
                                                style="background-size: cover; width: auto; max-height: 100%;" />

                                        </div>
                                    </a>
                                </div>

                            </div>

                        </tab-pane>


                    </tabs>

                </card>
            </div>
        </div>

    </div>
</template>
<script>
import { TabPane, Tabs } from 'src/components';
import { api_url, datumMixin } from '../../env';

export default {
    name: "ModelProfile",
    mixins: [datumMixin],
    components: {
        TabPane,
        Tabs
    },
    data() {
        return {
            id: 0,
            user_id: 0,
            loggedInUserId: 0,
            model: [],
            categories: [],
            posts: []
        };
    },
    methods: {
        getModel() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/models/' + this.id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            let model = response.model[0]
                            model.personal_details = JSON.parse(model.personal_details)
                            model.location_details = JSON.parse(model.location_details)
                            model.payment_details = JSON.parse(model.payment_details)
                            model.services = JSON.parse(model.services)
                            this.model = model

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })
        },
        getModelByUserId() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/models_by_user_id?user_id=' + this.user_id, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            let model = response.model[0]
                            model.personal_details = JSON.parse(model.personal_details)
                            model.location_details = JSON.parse(model.location_details)
                            model.payment_details = JSON.parse(model.payment_details)
                            model.services = JSON.parse(model.services)

                            this.model = model

                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })
        },
        getCategories() {

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            this.$http.get(api_url + '/categories', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.categories = response.categories
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        getPosts() {

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/posts?model_id=' + this.id + '&type=2', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.posts = response.posts
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        },

    },
    created() {
        this.id = this.$route.params.id;
        if (this.id == null) {
            this.user_id = this.$route.params.user_id;
            this.getModelByUserId();
        }
        else{
            this.getModel();
        }
        this.getCategories();
        this.getPosts();
        this.loggedInUserId = localStorage.getItem('user_id');
    }
};
</script>
<style lang="scss">
.profile {
    display: table-row;
}

.profile b {
    display: table-cell;
    padding-right: 1em;

}
</style>
<style scoped>
#edit-btn {
    color: #b448f4;
    transition: opacity 0.3s;
}
</style>
  