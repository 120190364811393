<template>
    <div class="col-12">
        <h2 class="card-title">Create Model</h2>
       
        <card>
                    <form @submit.prevent="validate">
                        <div>
                        
                            <div class="row mt-5">
                                <div class="col-12">
                                    <label>Display Picture (Shown on Escorts Page)</label>
                                    <image-upload @change="onImageChange" select-text="Select Image" :src="src"
                                        style="width: 100% ;" />


                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12">

                                    <ValidationProvider name="firstName" rules="required|min:2"
                                        v-slot="{ passed, failed, errors }">
                                        <label>First Name</label>
                                        <base-input required v-model="firstName" placeholder="First Name"
                                            addon-left-icon="tim-icons icon-single-02" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Last Name</label>
                                    <ValidationProvider name="lastName" rules="required|min:2"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="lastName" placeholder="Last Name"
                                            addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Email</label>
                                    <ValidationProvider name="email" rules="required|email"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="email" placeholder="Email"
                                            addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Password</label>
                                    <ValidationProvider name="password" rules="required|min:2" v-slot="{ passed, failed, errors }">
                                    <base-input required v-model="password" placeholder="Password"
                                        addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                    </base-input>
                                    </ValidationProvider>
                                    <label>Date of  Birth</label>
                                    <ValidationProvider name="DOB" rules="required" v-slot="{ passed, failed, errors }">
                                        <base-input :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                            :error="errors[0]">
                                            <el-date-picker type="date" placeholder="Date of birth" v-model="date_of_birth">
                                            </el-date-picker>
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Bust Size</label>
                                    <ValidationProvider name="bust_size" 
                                        v-slot="{ passed, failed, errors }">
                                        <base-input v-model="bust_size" placeholder="In cm(Shown on profile)"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Time Availability</label>
                                    <ValidationProvider name="availability" rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="availability"
                                            placeholder="1am - 3am(Shown on profile)"
                                            addon-left-icon="tim-icons icon-watch-time" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Languages</label>
                                    <ValidationProvider name="languages" rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="languages"
                                            placeholder="Languages - comma sepatated(Shown on profile)"
                                            addon-left-icon="tim-icons icon-badge" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Country</label>
                                    <ValidationProvider name="country" rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="country" placeholder="Eg (Kenya , Uganda )"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12">

                                    <label>Middle  Name</label>
                                    <ValidationProvider name="middleName" rules="required|min:2"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="middleName" placeholder="Middle Name"
                                            addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Pet Name</label>
                                    <ValidationProvider name="petName" rules="required|min:2"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="petName" placeholder="Pet Name (Shown on Home)"
                                            addon-left-icon="tim-icons icon-heart-2" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Phone</label>
                                    <ValidationProvider name="phone" rules="required|numeric"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="phone" placeholder="Phone(Not shown to public)"
                                            addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Hair Color</label>
                                    <ValidationProvider name="hair_color" rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="hair_color"
                                            placeholder="Hair Color e.g Black etc)"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Height</label>
                                    <ValidationProvider name="height"  v-slot="{ passed, failed, errors }">
                                        <base-input  v-model="height" placeholder="Height(Shown on profile)"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Hip  Size</label>
                                    <ValidationProvider name="hip_size" 
                                        v-slot="{ passed, failed, errors }">
                                        <base-input v-model="hip_size" placeholder="Hip Size(in cm)"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>Street</label>
                                    <ValidationProvider name="street" rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input v-model="street" placeholder="Street (Kijabe , Moi Avenue )"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                                    <label>City</label>
                                    <ValidationProvider name="hip_size" rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input required v-model="city" placeholder="City (Nairobi, Nakuru  etc)"
                                            addon-left-icon="tim-icons " :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>

                                   
                                </div>

                                <base-button  type="primary" @click="addModel()">Add Model</base-button>

                               
                            </div>
                        </div>
                    </form>
        </card>
    </div>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { api_url } from '../../env';

import {
    ImageUpload,

} from 'src/components/index';
import { color } from "d3";

extend("email", email);
extend("required", required);
extend("numeric", numeric);


export default {
    name: "AgentAddModel",
    components: {
        ImageUpload,
        [DatePicker.name]: DatePicker,
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            middleName: '',
            petName: '',
            email: '',
            password: '',
            phone: '',
            date_of_birth: '',
            profile_image: '',
            src: '',
            languages: '',
            hip_size: '',
            height: '',
            availability: '',
            bust_size: '',
            hair_color: '',
            street: '',
            city:'',
            country:''

        };
    },
    methods: {
      
      

        addModel() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",

            };

            var formdata = new FormData();
            formdata.append("first_name", this.firstName);
            formdata.append("last_name", this.lastName);
            formdata.append("middle_name", this.middleName);
            formdata.append("nick_name", this.petName);
            formdata.append("date_of_birth", this.date_of_birth);
            formdata.append("email", this.email);
            formdata.append("password", this.password);
            formdata.append("mobile", this.phone);
            formdata.append("profile_image", this.profile_image);
            formdata.append("languages", this.languages);
            formdata.append("hip_size", this.hip_size);
            formdata.append("height", this.height);
            formdata.append("availability", this.availability);
            formdata.append("bust_size", this.bust_size);
            formdata.append("hair_color", this.hair_color);
            formdata.append("country", this.country);
            formdata.append("city", this.city);
            formdata.append("street", this.street);

            this.$http.post(api_url + '/agency/model/create', formdata, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    console.log(response)
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            console.log(response["message"]);
                            this.alertNotification("top", "right", "success", response.message)
                            this.$router.push('/agents/manageModels')
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }


                })
        },
        onImageChange(file) {
            this.profile_image = file;
        },
        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        }

    },
    created() {
    }
};
</script>
<style></style>
  