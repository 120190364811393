<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div>
                <h5 class="info-text">
                    How you will get paid
                </h5>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-5">

                        <ValidationProvider name="Mpesa Number" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="mobileNumber" placeholder="Mpesa Number"
                                addon-left-icon="tim-icons icon-money-coins" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <ValidationProvider name="Hourly Rate" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="hourly_rate" placeholder=" Hourly Rate in KSH"
                                addon-left-icon="tim-icons icon-money-coins" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                        <ValidationProvider name="Overnight rate" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input required v-model="overnight_rate" placeholder="Overnight Rate in KSH"
                                addon-left-icon="tim-icons icon-money-coins" :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>

                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";

import {
    ImageUpload,

} from 'src/components/index';

import { api_url } from '../../../env';


export default {
    name: "PaymentDetails",

    data() {
        return {
            mobileNumber: '',
            hourly_rate: '',
            overnight_rate: ''

        };
    },
    methods: {
        getUser() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };

            this.$http.get(api_url + '/user', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            response.user = response.user[0]
                            let payment_details = JSON.parse(response.user.payment_details)


                            this.mobileNumber = payment_details.phone_number
                            this.hourly_rate = payment_details.hourly_rate
                            this.overnight_rate = payment_details.overnight_rate
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                })
        },
        addPaymentDetails() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),

            };
            var data = {
                'phone_number': this.mobileNumber,
                'hourly_rate': this.hourly_rate,
                'overnight_rate': this.overnight_rate
            }

            this.$http.post(api_url + '/models/addPaymentDetails', data, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            this.alertNotification("top", "right", "success", response.message)
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }


                })
        },
        alertNotification(verticalAlign, horizontalAlign, color, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: color
            });
        },
        validate() {
            return this.$refs.form.validate().then(res => {
                this.$emit("on-validated", res);
                this.addPaymentDetails();
                //redirect user to createPost page
                this.$router.push({ name: 'Home' });
                return res;
            });
        },

    },
    created() {
        this.getUser()

    }
};
</script>
<style></style>
  