<template>
  <Stories :stories="items" :duration="10000" :showOuterContent="true" v-if="loaded"/>
</template>
  
<script>
import Stories from "vue-stories-instagram";
import { api_url, datumMixin } from '../../env';


export default {
  components: { Stories },
  mixins: [datumMixin],
  data() {
    return {
      model_id: 0,
      items: [],
      loaded: false,
      
    };
  },
  methods: {
    getModelStories() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/posts?type=1&model_id=' + this.model_id, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {


              for (let index = 0; index < response.models.length; index++) {
                const model = response.models[index];
                let personal_details = (model.personal_details) ? JSON.parse(model.personal_details) : null
                model.personal_details = personal_details

                let story_images = []
                for (let index = 0; index < model.posts.length; index++) {
                  const post = model.posts[index];
                  story_images[index] = {
                    url: post.path,
                    viewed: false
                  }
                }
                this.items[index] = {
                  username: personal_details.nick_name,
                  picture: personal_details.profile_path,
                  time: "",
                  images: story_images,
                }
                console.log(JSON.stringify(this.items))
                this.loaded = true
              }
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },

  },
  created() {
    this.model_id = this.$route.params.model_id;
    this.getModelStories();
  }
};
</script>