<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4 ml-auto">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-login card-white">
              <template slot="header">
                <img src="img/card-primary.png" alt="" />
                <h1 class="card-title">Log in</h1>
              </template>

              <div>
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                  <base-input required v-model="email" type="email" placeholder="Email"
                    addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <ValidationProvider name="password" rules="required|min:5" v-slot="{ passed, failed, errors }">
                  <base-input required v-model="password" placeholder="Password"
                    addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>
              </div>

              <div slot="footer">
                <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                  Get Started
                </base-button>
                <div class="pull-left">
                  <h6>
                    <router-link class="link footer-link" to="/register">
                      Create Account
                    </router-link>
                  </h6>
                </div>

                <div class="pull-right">
                  <h6><a href="#pablo" class="link footer-link">Need Help?</a></h6>
                </div>
              </div>
            </card>
          </form>
        </ValidationObserver>
      </div>
      <div class="col-md-6 ml-auto">
        <img src="/img/datum/purple_logo1.png" height="100">


        <div class="info-area info mt-5 mb-3">
          <div class="icon icon-warning">
            <i class="tim-icons icon-square-pin"></i> Escorts
          </div>

        </div>
        <div class="info-area info mb-3">
          <div class="icon icon-primary">
            <i class="tim-icons icon-satisfied"></i> Hook Ups
          </div>
          <div class="description">

          </div>
        </div>
        <div class="info-area info mb-3">
          <div class="icon icon-info">
            <i class="tim-icons icon-video-66"></i> Premium Content
          </div>

        </div>
        <div class="info-area info">
          <div class="icon icon-danger">
            <i class="tim-icons icon-heart-2"></i> Dating
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { api_url,datumMixin } from "../../env"

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  mixins : [datumMixin],
  data() {
    return {
      email: "",
      password: "",
      subscribe: true
    };
  },
  methods: {
    submit() {
      const payload = {
        email: this.email,
        password: this.password,
      }

      this.$http.post(api_url + '/login', payload)
        .then(response => {
          return response.json()
        }, response => {
          return false
        })
        .then(response => {
          if (!response) {
            this.alertNotification("bottom", "right", "warning", "An error occurred")
            return
          }

          if (response["status"] != "true") {
            this.alertNotification("top", "right", "danger", response["message"])
            return
          } else {
            //store data to store
            console.log(response["user"]["id"])
            // this.$store.commit("set", ["tokem", response["token"]]);
            // this.$store.commit("set", ["user_id",response["user"]["id"]]);
            // this.$store.commit("set", ["user_type", response["user"]["user_type"]]);
            // this.$store.commit("set", ["email", response["user"]["email"]]);
            // this.$store.commit("set", ["first_name", response["user"]["first_name"]]);
            // this.$store.commit("set", ["middle_name", response["user"]["middle_name"]]);
            // this.$store.commit("set", ["agency_name", response["user"]["agency_name"]]);
            // this.$store.commit("set", ["last_name", response["user"]["last_name"]]);
            localStorage.setItem('token', response["token"])
            localStorage.setItem('user_type', response["user"]["user_type"])
            localStorage.setItem('user_id', response["user"]["id"])
            this.alertNotification("top", "right", "success", response["message"])
            if (response["user"]["user_type"] == 1)
              this.$router.push('admin/analytics')
            else
              this.$router.push('home')
          }

        })
    },
  
  },
  mounted() {
    //console.log(this)
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
  