<template>
    <div class="row">

        <div class="col-md-8 ml-auto mr-auto">
            <div>
                <card class="mb-3">
                    <video-player :options="videoOptions1" />
                    <h4 class="card-title">Live</h4>


                    <div class="d-flex justify-content-between align-items-center">
                        <div class="p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="p-2">
                                    <i class="tim-icons icon-heart-2"></i>
                                </div>
                                <div class="p-2">
                                    <i class="tim-icons icon-chat-33"></i>
                                </div>
                                <div class="p-2">
                                    <i class="tim-icons icon-send "></i>
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <span class="badge badge-success tips-badge">$53 tips</span>
                        </div>
                        <div class="p-2">
                            <div class="d-flex justify-content-end align-items-center">
                                <div class="p-2">
                                    <a class="btn btn-primary btn-tip">Send Tip</a>
                                </div>
                                <div class="p-2">
                                    <i class="tim-icons icon-book-bookmark "></i>
                                </div>
                            </div>

                        </div>

                    </div>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </card>
                <card class="mb-3">
                    <video-player :options="videoOptions2" />
                    <h4 class="card-title">Live</h4>


                    <div class="d-flex justify-content-between align-items-center">
                        <div class="p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="p-2">
                                    <i class="tim-icons icon-heart-2"></i>
                                </div>
                                <div class="p-2">
                                    <i class="tim-icons icon-chat-33"></i>
                                </div>
                                <div class="p-2">
                                    <i class="tim-icons icon-send "></i>
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <span class="badge badge-success tips-badge">$53 tips</span>
                        </div>
                        <div class="p-2">
                            <div class="d-flex justify-content-end align-items-center">
                                <div class="p-2">
                                    <a class="btn btn-primary btn-tip">Send Tip</a>
                                </div>
                                <div class="p-2">
                                    <i class="tim-icons icon-book-bookmark "></i>
                                </div>
                            </div>

                        </div>

                    </div>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </card>
            </div>
         



        </div>




    </div>
</template>
<script>

import VideoPlayer from 'src/components/Video.vue';


export default {
    name: 'live-feed',
    layout: 'default',
    components: {
        VideoPlayer
    },
    data() {
        return {
            videoOptions1: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src:
                            '/videos/livevideodemo.mp4',
                        type: 'video/mp4'
                    }
                ]
            },
              videoOptions2: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src:
                            '/videos/livedemo2.mp4',
                        type: 'video/mp4'
                    }
                ]
            },
        };
    }

};
</script>
<style>
@media screen and (min-width: 991px) {
    .tim-icons {
        font-size: 3em;
    }

    .tips-badge {
        font-size: 20px;
    }


}

@media screen and (min-width: 700px) and (max-width: 990px) {
    .tim-icons {
        font-size: 3em;
    }
}

@media screen and (max-width: 700px) {
    .tim-icons {
        font-size: 1.5em;
    }

    .btn-tip {
        font-size: 10px;
        padding: 10px;
    }
}
</style>
  